/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component, Fragment } from 'react';
import {
	Dropdown,
	DropdownItem,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import './ManageHashtags.css';
import { getHashtagGroups, getHashtags } from '../../Home/ManageHashtags/ManageHashtags.action';

class ManageHashtags extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			hashtagList: [],
			currentHashtagList: [],
			chosenHashtagList: {
				_id: '',
				title: '',
			},
			hashtags: [],
		};
	}

	componentDidMount() {
		const { isBulkUploadImages, additionalDropdownItems } = this.props;
		const defaultHashtagsCategory = { _id: 'select-hashtags', title: 'Select hashtags' };
		getHashtagGroups()
			.then(res => {
				if (this.isCancelled) {
					return;
				}
				const hashtagList = [defaultHashtagsCategory].concat(res.items);
				if (hashtagList.length && additionalDropdownItems) {
					const { order = 0 } = additionalDropdownItems;
					res.items.splice(order, 0, additionalDropdownItems);
					hashtagList.splice(order, 0, additionalDropdownItems);
				}

				if (!this.props.detectedFromURL) {
					this.setState({
						hashtagList,
						chosenHashtagList: defaultHashtagsCategory,
						currentHashtagList: res.items.filter(item => item._id !== 'trending-hashtags'),
					});
				} else {
					this.setState({
						hashtagList,
						currentHashtagList: hashtagList.filter(item => item._id !== 'trending-hashtags'),
					});
				}
			});

		getHashtags()
			.then(res => {
				if (this.isCancelled) {
					return;
				}

				const hashtags = res.items.map(item => ({ ...item, title: `#${item.title}` }));
				this.setState({
					hashtags,
				});
			});
	}

	componentWillReceiveProps(props) {
		const { hashtagList, currentHashtagList, chosenHashtagList } = this.state;
		const { isBulkUploadImages } = this.props;
		const defaultHashtagsCategory = { _id: 'select-hashtags', title: 'Select hashtags' };
		const urlRelated = { _id: 'url', title: 'URL Related' };
		if (props.detectedFromURL && !hashtagList.some(el => el._id === 'url')) {
			this.setState({
				hashtagList: hashtagList.concat(urlRelated),
				currentHashtagList: [defaultHashtagsCategory].concat(currentHashtagList),
				chosenHashtagList: urlRelated,
			}, props.changeHashtagList(urlRelated, []));
		} else if (!props.detectedFromURL) {
			if (chosenHashtagList && chosenHashtagList._id === 'url') {
				this.setState({
					hashtagList: hashtagList.filter(item => item._id !== 'url'),
					chosenHashtagList: hashtagList[0],
					currentHashtagList: hashtagList.filter(item => item._id !== 'url').filter(item => item._id !== hashtagList[0]._id),
				}, props.changeHashtagList(hashtagList[0], []));
			} else {
				this.setState({
					hashtagList: hashtagList.filter(item => item._id !== 'url'),
					currentHashtagList: currentHashtagList.filter(item => item._id !== 'url'),
				});
			}
		}
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	chooseHashTagList = (category) => {
		const hashTagList = this.state.hashtagList.filter((item) => item._id !== category._id);
		const { suggestionHashtags } = this.props;

		const allHashtags = this.state.hashtags.concat(suggestionHashtags);
		this.setState({
			chosenHashtagList: category,
			currentHashtagList: hashTagList,
		}, () => this.props.changeHashtagList(category, allHashtags));
	};

	toggleDropdown = () => {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen,
		}));
	};

	render() {
		const {
			chosenHashtagList: {
				title = 'Create a new list',
			},
		} = this.state;
		return (
			<Fragment>
				<div className="manage-hashtags-picker">
					<div className="hashtags-list">
						<Dropdown
							isOpen={this.state.dropdownOpen}
							toggle={this.toggleDropdown}
							id="calendar-page-dropdown"
						>
							<DropdownToggle color="drop-down" caret>
								<div className="drop-down-toggle">
									{title}
								</div>
							</DropdownToggle>
							<DropdownMenu className="drop-down-menu">
								<Scrollbars style={{ width: '100%' }} autoHeight>
									{
										this.state.currentHashtagList.map(list => (
											<DropdownItem
												key={list._id}
												onClick={() => this.chooseHashTagList(list)}
											>
												{list.title}
											</DropdownItem>
										))
									}
								</Scrollbars>
							</DropdownMenu>
						</Dropdown>
					</div>
				</div>
			</Fragment>
		);
	}
}

ManageHashtags.defaultProps = {
	isBulkUploadImages: false,
};

export default ManageHashtags;

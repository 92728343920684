/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, September 2020
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as selectors from '../../selectors';

import { getCurrentUser } from '../../actions/user';
import { getSettings } from '../../actions/settings';
import { getConfig } from '../../actions/config';
import { getAccountsFromDB } from '../../actions/accounts';
import { getNotifications } from '../../actions/notification';
import FetchStoreLifeCycle from '../../helpers/components/requestLifeCycle/requestLifeCycle';

import VoiceOver from '../../common/components/VoiceOver/VoiceOver';
import NavBar from './components/NavBar/NavBar';
import RoutesContainer from './components/RoutesContainer';
import { getSuccessData } from '../../helpers/store';

import reactGoogleAnalytics from '../../helpers/reactGoogleAnalytics';
import detectCurrentEnvironment from '../../helpers/detectCurrentEnvironment';
import { UserSubscriptionService, UserSubscriptionLimitsContext } from '../UserSubscriptions/UserSubscriptionService';
import UpgradePlanModal from '../../common/components/UpgradePlanModal/UpgradePlanModal';
import FreeUserSubscriptionModal from '../../common/components/FreeUserSubscriptionModal/FreeUserSubscriptionModal';

const Layout = ({
	getNotificationsAction,
	getCurrentUserAction,
	getSettingsAction,
	getConfigAction,
	getAccountsFromDBAction,
	user,
	settings,
	accountsList,
	history,
}) => {
	const upgradeSubscriptionType = UserSubscriptionService.getUserNextUpgradeSubscriptionInfo(user.response)?.plan;
	const [isUpgradeModalShown, setIsUpgradeModalShown] = useState(null);
	const [upgradeModalAccount, setUpgradeModalAccount] = useState({});
	const [upgradeModalMetadata, setUpgradeModalMetadata] = useState({});
	const [upgradeModalCustomMessage, setUpgradeModalCustomMessage] = useState('');

	const [limits, setLimits] = useState(null);

	useEffect(() => {
		const currentEnvironment = detectCurrentEnvironment();
		if (currentEnvironment === 'production') {
			reactGoogleAnalytics.track();
		}
	}, [history.location.pathname]);

	useEffect(() => {
		getCurrentUserAction();
		getSettingsAction();
		getConfigAction();
		getNotificationsAction();
		getAccountsFromDBAction();

		UserSubscriptionService.getLimits()
			.then((response) => setLimits(response.limits));
	}, []);

	const isSettingsPage = history.location.pathname.includes('/settings');
	const isUpgradeProfilePage = history.location.pathname.includes('/upgrade-profile');

	const userSubscriptionPlan = user.response && user.response.subscription && user.response.subscription.plan;

	if (user.response && user.response.subscription && !userSubscriptionPlan &&
		!isUpgradeProfilePage && !isSettingsPage) {
		history.push('/settings');
	}

	const updateUserInfo = useCallback(() => getCurrentUserAction(true), [user]);
	return (
		<div style={{ minWidth: 1440 }}>
			<div className={settings && getSuccessData(settings).colorBlindnessActivated && 'color-blind'} style={{ height: '100vh' }}>
				<FetchStoreLifeCycle entities={{ user, settings, accountsList }}>
					<UserSubscriptionLimitsContext.Provider value={{
						limits,
						currentSubscription: user.response ? user.response.subscription : null,
						setIsUpgradeModalShown,
						setUpgradeModalAccount,
						setUpgradeModalMetadata,
						setUpgradeModalCustomMessage,
					}}
					>
						<NavBar />
						<RoutesContainer updateUserInfo={updateUserInfo} />
						{/* <VoiceOver ailLoaderLength={30} ailLoaderRadius={5.6} /> */}
					</UserSubscriptionLimitsContext.Provider>
				</FetchStoreLifeCycle>
			</div>
			<UpgradePlanModal
				showText
				isOpen={!!isUpgradeModalShown}
				onClose={() => setIsUpgradeModalShown(false)}
				upgradeModalAccount={upgradeModalAccount}
				upgradeSubscriptionType={upgradeSubscriptionType}
				upgradeModalMetadata={upgradeModalMetadata}
				limits={limits}
				user={user}
				customMessage={upgradeModalCustomMessage}
			/>
			<FreeUserSubscriptionModal />
		</div>
	);
};

const componentWithRouter = withRouter(Layout);

const mapStateToProps = state => ({
	user: selectors.getCurrentUser(state),
	settings: selectors.getSettings(state),
	accountsList: selectors.getAccounts(state),
	config: selectors.getConfig(state),
});

const mapDispatchToProps = dispatch => ({
	getCurrentUserAction: bindActionCreators(getCurrentUser, dispatch),
	getSettingsAction: bindActionCreators(getSettings, dispatch),
	getConfigAction: bindActionCreators(getConfig, dispatch),
	getNotificationsAction: bindActionCreators(getNotifications, dispatch),
	getAccountsFromDBAction: bindActionCreators(getAccountsFromDB, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(componentWithRouter);

/* eslint-disable react/no-danger */
/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import { bindActionCreators } from 'redux';

import moment from 'moment-timezone';

import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Form,
	FormGroup,
	Label,
	Row,
	Col,
	CustomInput,
	Alert,
} from 'reactstrap';

import CalendarImageUploader from '../CalendarImageUploader/CalendarImageUploader';
import CalendarContentPicker from '../CalendarContentPicker/CalendarContentPicker';
import DropdownSelectAccount from '../DropdownSelectAccount/DropdownSelectAccount';
import ManageHashtags from '../CalendarManageHashtags/ManageHashtags';
import CalendarMonth from '../CalendarMonth/CalendarMonth';
import Quotes from '../../Library/Quotes/Quotes';
import CalendarMonthPicker from '../CalendarMonthPicker/CalendarMonthPicker';
import CalendarNavArrows from '../CalendarNavArrows/CalendarNavArrows';
import AlertModal from '../../../common/components/AlertModal/AlertModal';
import ConfirmModal from '../../../common/components/ConfirmModal/ConfirmModal';
import LibrarySimilarityModal from '../../Library/LibrarySimilarityModal/LibrarySimilarityModal';
import PostLimitsWarningTooltip from '../../../common/components/PostLimitsWarningTooltip/PostLimitsWarningTooltip';
import RemindToInstallWoofyAppModal from '../../../common/components/RemindToInstallWoofyAppModal/RemindToInstallWoofyAppModal';
import TextMessageCounter from '../../../common/components/TextMessageCounter/TextMessageCounter';
import AddUTMParamsToLink from '../../../common/components/AddUTMParamsToLink/AddUTMParamsToLink';
import AddTitleToGMBPost from '../../../common/components/AddTitleToGMBPost/AddTitleToGMBPost';
import CaptionGenerationModal from '../../../componentsNew/CaptionGenerationModal/CaptionGenerationModal';

import {
	createPost,
	updatePost,
	getPostsCount,
	deleteFiles,
	chatGptTittleGeneration,
} from './CalendarPostModal.action';
import { getAccountsFromDB } from '../../../actions/accounts';
import { getSettings } from '../../../actions/settings';

import MessageEditor from '../../../common/components/MessageEditor/MessageEditor';
import LanguageTranslator from '../../../common/components/LanguageTranslator/LanguageTranslator';
import {
	urlRegexp,
	getLinksFromText,
	getHashtagsFromText,
	addOrRemoveValueFromText,
	getTextWithUTMParams,
} from '../../../common/textHelper';

import {
	getUTCDateWithSettingTimezone,
	getNewDateWithoutSettingTimezone,
	getLocalDate,
	getDefaultTimeForSchedule,
} from '../../../common/dateHelper';
import { getCalendar } from '../../../common/calendarHelper';
import {
	getEnabledAccounts,
	isGMBAccountsWereSelected,
	needToShowModalForInstallMobileApp,
} from '../../../helpers/accounts';
import SimilarityModal from '../../../common/components/SimilarityModal/SimilarityModal';
import ModalLoader from '../../../common/components/ModalLoader/ModalLoader';
import * as selectors from '../../../selectors';
import { getConfig } from '../../Settings/Settings.action';
import ShowingVideoGuideButton from '../../ShowingVideoGuideButton/ShowingVideoGuideButton';

import getArrayOfUrlsFromText from '../../../helpers/getArrayOfUrlsFromText';
import renderHourSelect from '../../../helpers/components/renderHourSelect';

import { woofyText } from '../../../constants/woofyText';
import { clickable } from '../../../helpers/makeElementClicable';
import Loader from '../../../common/components/LoaderAnimation/Loader';
import { getCurrentUser } from '../../../actions/user';
import {
	UserSubscriptionLimitsContext,
	UserSubscriptionService,
} from '../../UserSubscriptions/UserSubscriptionService';
import { getNotifications } from '../../../actions/notification';
import { containsGifImages, isGifImage } from '../../../utils/utils';
import postIcon from '../../../images/post-icon.svg';

import './CalendarPostModal.css';
import { SocialIntegrationType } from '../../../constants/mediaTypes';
import DailyLimitsModal from '../../../common/components/DailyLimitsModal/DailyLimitsModal';
// import CalendarImageImageUploaderItem
// 	from '../CalendarImageUploader/CalendarImageImageUploaderItem/CalendarImageImageUploaderItem';
import CalendarSummarizeModal from '../CalendarSummarizeModal/CalendarSummarizeModal';
import ManageCTA from '../CalendarManageCTA/ManageCTA';
import DropdownSelectCategory from '../../Library/DropdownSelectCategory/DropdownSelectCategory';
import { createLibraryPost } from '../../Library/LibraryPosts.action';
import pdfWaveIcon from '../../../images/pdf-icon.png';
import {
	subsActions,
	subsErrorReasons,
} from '../../../constants/subscriptionValidation';
import postWarningTooltip from '../../../images/postWarningTooltip.svg';
import UpgradeNowButton from '../../../common/components/UpgradePlanModal/UpgradeNowButton';

const ManageEmojis = React.lazy(() =>
// eslint-disable-next-line function-paren-newline
	import(
		/* webpackChunkName: "manage-emojis" */ '../CalendarManageEmojis/ManageEmojis'));

const soundWaveIcon =
  'https://hello-woofy.s3.eu-north-1.amazonaws.com/bg_audio.png';

class CalendarPostModal extends React.Component {
  static contextType = UserSubscriptionLimitsContext;

  errorMessageRef = React.createRef();

  constructor(props) {
  	super(props);

  	this.state = {
  		post: {},
  		postsCount: {},
  		createdPost: null,
  		textValue: props.initialText || '',
  		errorMessage: '',
  		postDateStatus: 'now',
  		showDayPicker: false,
  		currentDate: new Date(),
  		currentMonth: moment().startOf('month'),
  		socialImages: [],
  		images: props.initialImages || [],
  		video: {},
  		audio: {},
  		pdf: {},
  		dropdownOpen: false,
  		toggleCategory: false,
  		audioThumbnail: [],
  		discoverPostImage: '',
  		quotes: null,
  		listOfHashtags: {},
  		selectedOptions: [],
  		hashtagsList: [],
  		postTime: '',
  		accounts: [],
  		selectedAccounts: [],
  		dropdownAccountOpen: false,
  		similar: null,
  		similarityScore: { similarityScore: {} },
  		imageSuggestion: false,
  		addToText: '',
  		unmounted: false,
  		checkSimilarModal: false,
  		loader: false,
  		isDisableSuggestingImages: false,
  		/* <AlertModal /> */
  		alertModalMessage: '',
  		isOpenAlertModal: false,
  		isOpenConfirmModal: false,
  		isOpenConfirmPostModal: false,
  		isOpenInstaBusinessGifStaticModal: false,
  		isOpenInstaBusinessPostLimitsModal: false,
  		isOpenInstaBusinessMultipleImagesModal: false,
  		isOpenPinterestMultipleImagesModal: false,
  		isOpenFacebookGroupIssueModal: false,
  		suggestionHashtags: [],
  		emojiList: undefined,
  		addEmoji: null,
  		emojiCount: 15,
  		libraryPostId: '',
  		librarySimilarityModal: false,
  		conflictingCampaignsIds: [],
  		config: {},
  		limitReachedModal: false,
  		isRemindToInstallWoofyAppModalOpen: false,
  		hashtagsImageUrl: '',
  		isMediaFilesLoading: false,
  		settings: {},
  		mediaMetadata: [],
  		isAddTitleToGMBPostSelected: false,
  		titleOfPost: '',
  		imgRatioValidate: [],
  		summarizeModal: false,
  		messageEditorFocused: true,
  		isOpenTwitterPostLimitsModal: false,
  		link: '',
  		callToAction: {
  			url: '',
  			actionType: 'default',
  		},
  		accountsMetadata: [],
  		links: [],
  		typeOfPostYoutube: 'public',
  		openIGBModal: false,
  		postingVideoForKids: false,
  		postingVideoShort: false,
  		upgradeSubsBtn: false,
  		existingPost: props.existingPost || false,
  		showChatGptIconInTitleSec: false,
  		openCaptionGenerationModal: false,
  	};

  	this.onToggle = this.onToggle.bind(this);
  	this.onPostValueChange = this.onPostValueChange.bind(this);
  	this.createOrUpdatePost = this.createOrUpdatePost.bind(this);
  	this.onAccountChange = this.onAccountChange.bind(this);
  	this.initPost = this.initPost.bind(this);
  	this.onMessageChange = this.onMessageChange.bind(this);
  	this.changeCurrentPostDate = this.changeCurrentPostDate.bind(this);
  	this.getSelectedDates = this.getSelectedDates.bind(this);
  	this.onSelectDates = this.onSelectDates.bind(this);
  	this.onNext = this.onNext.bind(this);
  	this.getQuote = this.getQuote.bind(this);
  	this.getQuotes = this.getQuotes.bind(this);
  	this.getLink = this.getLink.bind(this);
  	this.setByEditPost = this.setByEditPost.bind(this);
  }

  componentDidMount() {
  	const {
  		accountsList: { response: { items } = {} } = {},
  		libraryPost: { libraryPostId = '' } = {},
  		text,
  		blockedPostData = {},
  		getSettingsAction,
  		settings: { response: userSettings = {} },
  		initialImages,
  		blockedAccountList,
  		notFoundData,
  	} = this.props;
  	if (notFoundData) {
  		return this.setState({ errorMessage: 'Selected post not found' });
  	}
  	getSettingsAction(true);
  	const {
  		libraryPost = {},
  		getAccountsFromDBAction,
  		getCurrentUserAction,
  	} = this.props;
  	getAccountsFromDBAction(true);
  	getCurrentUserAction(true);

  	const propsLibraryPost = Object.keys(libraryPost).length
  		? libraryPost
  		: null;
  	const propsBlockedPost = Object.keys(blockedPostData).length
  		? blockedPostData
  		: null;
  	const post = propsLibraryPost || propsBlockedPost || {};

  	this.initPost(post);

  	const {
  		message,
  		images = [],
  		video = {},
  		pdf = {},
  		postTitle = '',
  		callToAction,
  		accountsMetadata,
  		mediaMetadata,
  	} = post;

  	let selectedAccountsData =
      blockedAccountList && blockedAccountList.length !== 0
      	? [...this.state.selectedAccounts, ...blockedAccountList]
      	: this.state.selectedAccounts;
  	selectedAccountsData = selectedAccountsData.filter((ele) => !!ele._id);
  	this.setState({
  		post,
  		callToAction,
  		accountsMetadata: accountsMetadata || [],
  		mediaMetadata: mediaMetadata || [],
  		hashtagsList: [],
  		video: video || {},
  		pdf: 'url' in pdf ? { ...pdf, thumbnail: pdfWaveIcon } : {},
  		titleOfPost: postTitle,
  		isAddTitleToGMBPostSelected: !!postTitle,
  		selectedAccounts: selectedAccountsData,
  	});

  	if (text) {
  		this.setState({
  			textValue: text,
  		});
  	}

  	if (message) {
  		this.setState({
  			textValue: message,
  			images,
  			libraryPostId,
  		});
  	}

  	if (initialImages && initialImages.length) {
  		this.setState({
  			images: initialImages,
  		});
  	}

  	if (items) {
  		const accounts = getEnabledAccounts(items);
  		this.setState({ accounts });
  	}

  	if (Object.keys(userSettings).length) {
  		this.setState({ settings: userSettings });
  	}

  	if (this.props.editPost) {
  		this.setByEditPost();
  	}

  	getConfig().then((config) => {
  		this.setState({ config });
  	});

  	getPostsCount().then((postsCount) => {
  		this.setState({ postsCount });
  	});
  }

  componentWillReceiveProps(props) {
  	const {
  		accountsList: { response: { items } = {} } = {},
  		blockedPostData = {},
  		retry,
  	} = props;
  	if (Object.keys(blockedPostData).length) {
  		this.setState({
  			textValue: blockedPostData.message,
  			post: blockedPostData,
  		});
  	}

  	const { accounts } = this.state;
  	if (items.length !== accounts.length) {
  		const accountsList = getEnabledAccounts(items);
  		this.setState({ accounts: accountsList });
  	}
  	if (props.isOpen) {
  		this.initPost(this.state.post);
  		let foundSelectedAccounts = accounts.filter((acc) =>
  				this.props.blockedAccountList &&
          this.props.blockedAccountList.some((ele) => ele && ele._id === acc._id));
  		foundSelectedAccounts = foundSelectedAccounts.filter((ele) => !!ele._id);
  		if (foundSelectedAccounts.length !== 0 || !retry) {
  			this.setState({
  				errorMessage: this.state.errorMessage || '',
  				unmounted: false,
  			});
  		} else if (
  			retry &&
        foundSelectedAccounts.length !== this.props.blockedAccountList.length
  		) {
  			this.setState({
  				errorMessage: 'Some accounts are already deleted.',
  				unmounted: true,
  			});
  		}
  		if (props.postTitle) {
  			const post = this.state.post;
  			const postUrl = props.postUrl || '';
  			const textValue = `${props.postTitle} ${postUrl}`;
  			post.message = textValue;

  			this.setState({
  				textValue,
  				discoverPostImage: props.postImage,
  			});
  		}
  		// else if (props.editPost) {
  		// 	this.setByEditPost();
  		// }
  	}
  }

  componentDidUpdate() {
  	if (this.props.resizeHeight) {
  		this.props.resizeHeight();
  	}
  }

  onChangeHashtagList = (list, hashtags) => {
  	const newHashtags = hashtags
  		.filter((hashtag) => hashtag.groupId === list._id)
  		.map((item) => item.title);
  	this.setState({
  		listOfHashtags: list,
  		hashtagsList: [...new Set(newHashtags)],
  	});
  };

  onChangeMonth = (value) => {
  	const month = moment().startOf('month');
  	if (value >= month) {
  		this.setState({ currentMonth: value });
  	}
  };

  onToggle() {
  	const { editPost, closeExtModal = false, closePreviousModal } = this.props;

  	if (closePreviousModal) {
  		closePreviousModal();
  	}
  	if (this.state.showDayPicker) {
  		if (editPost) {
  			this.setState({
  				images: [],
  			});
  		}
  		this.setState({
  			showDayPicker: false,
  			checkSimilarModal: false,
  			createdPost: null,
  			librarySimilarityModal: false,
  			conflictingCampaignsIds: [],
  			isOpenConfirmModal: false,
  			isOpenConfirmPostModal: false,
  			isOpenInstaBusinessGifStaticModal: false,
  			isOpenInstaBusinessPostLimitsModal: false,
  			isOpenInstaBusinessMultipleImagesModal: false,
  			isOpenPinterestMultipleImagesModal: false,
  			isOpenTwitterPostLimitsModal: false,
  			isOpenFacebookGroupIssueModal: false,
  		});
  	} else {
  		this.props.toggle(this.state.isOpen);
  		this.setState({
  			isOpenConfirmModal: false,
  			isOpenConfirmPostModal: false,
  			isOpenInstaBusinessGifStaticModal: false,
  			isOpenInstaBusinessPostLimitsModal: false,
  			isOpenTwitterPostLimitsModal: false,
  			isOpenInstaBusinessMultipleImagesModal: false,
  			isOpenPinterestMultipleImagesModal: false,
  			isOpenFacebookGroupIssueModal: false,
  			postDateStatus: 'now',
  			textValue: '',
  			showDayPicker: false,
  			postTime: '',
  			images: [],
  			video: {},
  			audio: {},
  			pdf: {},
  			hashtagsList: [],
  			selectedAccounts: [],
  			listOfHashtags: {},
  			quotes: null,
  			currentMonth: moment().startOf('month'),
  			unmounted: true,
  			checkSimilarModal: false,
  			createdPost: null,
  			librarySimilarityModal: false,
  			conflictingCampaignsIds: [],
  		});
  	}
  	if (closeExtModal) {
  		closeExtModal();
  	}
  }

  removeRatioValidateItem = (imgUrl) =>
  	this.setState((prevState) => ({
  		imgRatioValidate: prevState.imgRatioValidate.filter((ratioData) => ratioData.url !== imgUrl),
  	}));

  onPostCreate = (post) => {
  	const {
  		postDateStatus,
  		postTime,
  		postingVideoForKids,
  		typeOfPostYoutube,
  		postingVideoShort,
  	} = this.state;
  	const {
  		limits,
  		setIsUpgradeModalShown,
  		setUpgradeModalMetadata,
  	} = this.context;
  	const { user } = this.props;
  	const userPostLimit =
      limits[user?.response?.subscription?.plan.split('_')[1]]?.libraryPosts;
  	const {
  		timezone,
  		getNotificationsAction,
  		user: { response: userData = {} },
  	} = this.props;
  	const isUserHaveSubscription = UserSubscriptionService.isUserHaveSubscription(userData);

  	const postType = post.draft ? 'drafts' : 'calendar';
  	this.setState({ loader: true });

  	let updatedPost;

  	if (postDateStatus === 'now') {
  		updatedPost = { ...post };
  	} else {
  		const postDateUTC = getUTCDateWithSettingTimezone(
  			moment(post.postDate).format('YYYY-MM-DD'),
  			postTime,
  			timezone,
  		);
  		updatedPost = { ...post, postDate: postDateUTC };
  	}

  	if (
  		updatedPost.audio &&
      updatedPost.audio.url &&
      updatedPost.audioThumbnail &&
      updatedPost.audioThumbnail.length > 0
  	) {
  		const [audioThumbnail] = updatedPost.audioThumbnail;
  		updatedPost.audio.thumbnail = audioThumbnail.image;
  	}

  	if (updatedPost.video && updatedPost.video.url) {
  		updatedPost.video.postingVideoForKids = postingVideoForKids;
  		updatedPost.video.typeOfPostYoutube = typeOfPostYoutube;
  		updatedPost.video.postingVideoShort = postingVideoShort;
  	}

  	createPost(updatedPost)
  		.then(({ resMessage, postData, message }) => {
  			if (this.state.toggleCategory) {
  				const variations = [];
  				const complianceEngineRules = {
  					ignoreComplianceCheck: true,
  				};

  				createLibraryPost({
  					...post,
  					complianceEngineRules,
  					variations,
  				}).catch((err) => {
  					if (
  						err.message ===
              `The limit of ${userPostLimit} posts in Library is reached.`
  					) {
  						this.props.toggle(false);
  						this.onToggle();
  						const createdPostData = {
  							open: true,
  							resMessage,
  							postType,
  							subMessage: '',
  							campaignWarningData: null,
  							postData,
  							errMessage: `The limit of ${userPostLimit} posts in Library is reached.`,
  							message: `The limit of ${userPostLimit} posts in Library is reached.`,
  						};
  						this.props.onSave(createdPostData);
  						if (this.props.closeExtModal) {
  							this.props.closeExtModal();
  						}
  						return setTimeout(
  							() => window.open('/upgrade-profile', '_blank'),
  							2500,
  						);
  					}
  					this.props.toggle(false);
  					this.onToggle();
  					const createdPostData = {
  						open: true,
  						resMessage,
  						postType,
  						subMessage: '',
  						campaignWarningData: null,
  						postData,
  						errMessage: "Sorry! we couldn't save a copy to library.",
  						message: "Sorry! we couldn't save a copy to library.",
  					};
  					this.props.onSave(createdPostData);
  					if (this.props.closeExtModal) {
  						this.props.closeExtModal();
  					}
  				});
  			}
  			message = `${message} ${
  				this.state.toggleCategory ? 'Save to library successfully.' : ''
  			}`;
  			this.props.toggle(false);
  			this.onToggle();
  			const createdPostData = {
  				open: true,
  				resMessage,
  				postType,
  				subMessage: '',
  				campaignWarningData: null,
  				postData,
  				message: message.length !== 0 ? message : '',
  			};
  			this.props.onSave(createdPostData);
  			if (this.props.closeExtModal) {
  				this.props.closeExtModal();
  			}
  		})
  		.catch((err) => {
  			console.log('\n\n\nerr', err);
  			if (
  				err.message ===
          'To auto post to Instagram Business Carousel please upgrade your plan to SMB or above.'
  			) {
  				setUpgradeModalMetadata({
  					reason: subsErrorReasons.no_access,
  					action: subsActions.instagramAutoPostCarousel,
  				});
  				return setIsUpgradeModalShown(true);
  			}
  			const {
  				errors = [],
  				message,
  				score = {},
  				postsCount,
  				status,
  				imgRatioValidate,
  				upgradeSubs,
  			} = err;

  			const [firstError] = errors;
  			const errorMessage = firstError ? firstError.msg : message;
  			this.setState({ errorMessage, upgradeSubsBtn: upgradeSubs });

  			if (imgRatioValidate && imgRatioValidate.length > 0) {
  				this.setState({ imgRatioValidate });
  				try {
  					this.errorMessageRef.current.scrollIntoView({
  						behavior: 'smooth',
  						block: 'center',
  					});
  				} catch (error) {
  					console.error('errorMessageRef.current.scrollIntoView():', error);
  				}
  			}
  			if (postsCount && !isUserHaveSubscription) {
  				this.setState({ limitReachedModal: true });
  			}

  			if (score.rejectedRule === 'complianceValidation' && status !== 403) {
  				this.setState({
  					similar: score.maxScore,
  					checkSimilarModal: true,
  					createdPost: updatedPost,
  					similarityScore: {
  						socialMedias: score.socialMedias,
  						library: score.library,
  					},
  					isPostCreate: true,
  				});
  			}
  			if (score.rejectedRule === 'campaignValidation' && status !== 403) {
  				this.setState({
  					createdPost: updatedPost,
  					librarySimilarityModal: true,
  					conflictingCampaignsIds: score.campaigns,
  				});
  			}
  		})
  		.finally(() => {
  			this.setState({ loader: false });
  			getNotificationsAction();
  		});
  	// const { post } = this.state;
  	// const { currentPost, loadCategories } = this.props;

  	// const libraryPostPromise = () => {
  	//   if (!currentPost || !currentPost._id) {
  	//     return createLibraryPost({ ...post, complianceEngineRules });
  	//   }
  	//   return updateLibraryPost({ ...post, complianceEngineRules });
  	// };
  	// libraryPostPromise()
  };

  onSelectAccount = (selectedAccount, withToggle = true) => {
  	const { post, selectedAccounts } = this.state;
  	let userSelectedAccounts;
  	const existingAccount = selectedAccounts.find((account) => account._id === selectedAccount._id);
  	if (existingAccount) {
  		userSelectedAccounts =
        selectedAccounts &&
        selectedAccounts.filter((account) => account._id !== selectedAccount._id);
  	} else {
  		userSelectedAccounts = [...selectedAccounts, selectedAccount];
  	}
  	const [firstAccount = {}] = userSelectedAccounts;
  	this.setState({
  		selectedAccounts: userSelectedAccounts,
  		post: { ...post, accountId: firstAccount._id },
  	});

  	if (withToggle) {
  		this.toggleAccount();
  	}
  };

  onPostValueChange(value) {
  	const textValue = addOrRemoveValueFromText(value, this.state.textValue);

  	this.setState({
  		textValue,
  		addToText: textValue.match(value) && this.state.textValue ? value : '',
  	});
  }

  onPostTimeChange = (e) => {
  	const postTime = e.target.value;
  	this.setState({ postTime });
  };

  onPostVideoYoutubeChange = (e) => {
  	this.setState({ typeOfPostYoutube: e.target.value });
  };

  onAccountChange(selected) {
  	const post = this.state.post;

  	if (selected.length) {
  		post.accountId = selected[0]._id;
  	}
  }

  onMessageChange(textValue) {
  	const { post } = this.state;
  	this.setState({
  		textValue,
  		post: { ...post, message: textValue },
  		addEmoji: null,
  		links: getLinksFromText(textValue),
  		showChatGptIconInTitleSec: textValue.length > 10,
  	});
  }

  handleTitleGeneration = () => {
  	const { post } = this.state;
  	chatGptTittleGeneration(post?.message)
  		.then((response) => {
  			this.setState({
  				titleOfPost: response?.message?.content,
  			});
  		})
  		.catch((err) => {
  			console.log('\n\n\nerr', err);
  			return this.setState({
  				errorMessage: err.message,
  			});
  		});
  };

  handleAppendCaption = (responseText) => {
  	this.setState((prevState) => ({
  		textValue: prevState.textValue + responseText,
  	}));
  };

  handleCloseCaptionGenerationModal = () => {
  	this.setState({
  		openCaptionGenerationModal: false,
  	});
  };

  handleGeneratePostFromVideo = (title, caption) => {
  	this.setState((prevState) => ({
  		titleOfPost: title,
  		textValue: prevState.textValue + caption,
  	}));
  }

  onSelectDates(day = moment()) {
  	const { post } = this.state;
  	const { postDate = '' } = post;
  	let postNewDate;
  	if (postDate) {
  		const hour = moment(postDate).format('HH');
  		const minute = moment(postDate).format('mm');
  		postNewDate = moment(day)
  			.set({ hour, minute })
  			.toDate();
  	} else {
  		postNewDate = new Date(day);
  	}
  	post.postDate = postNewDate;
  	this.setState({ post });
  }

  onNext() {
  	this.setState({
  		showDayPicker: false,
  		postDateStatus: 'schedule',
  	});
  }

  onRemove = (field) => {
  	const {
  		images,
  		video,
  		discoverPostImage,
  		audio,
  		pdf,
  		mediaMetadata,
  	} = this.state;

  	if (video && video.url === field.url) {
  		this.setState({
  			video: {},
  		});
  	}

  	if (pdf && pdf.url === field.url) {
  		this.setState({
  			pdf: {},
  		});
  	}

  	if (audio && audio.url === field.url) {
  		this.setState({
  			audio: {},
  		});
  	}

  	if (discoverPostImage && discoverPostImage === field.image) {
  		this.setState({
  			discoverPostImage: '',
  		});
  	}

  	const newArrayOfImages = images.filter((element) => {
  		if (typeof field === 'string' && typeof element === 'string') {
  			return element !== field;
  		} else if (typeof field === 'string' && typeof element === 'string') {
  			return element.id !== field.id;
  		} else if (typeof field === 'string' && typeof element === 'object') {
  			return element.image !== field;
  		}
  		return element.id !== field.id;
  	});

  	const newMediaMetadata = mediaMetadata.filter((data) =>
  		newArrayOfImages.find((newImg) => newImg.image === data.mediaId));
  	this.setState((state) => ({
  		...state,
  		images: newArrayOfImages,
  		mediaMetadata: newMediaMetadata,
  	}));
  };

  onRedirect = (page) => {
  	this.props.history.push(`/${getCalendar()}/${page}`);
  };

  setByEditPost = () => {
  	const { editPost: { event: editPost = {} } = {}, timezone } = this.props;
  	const {
  		post: {
  			postDate = '', message = '', video = {}, pdf = {},
  		} = {},
  		titleOfPost,
  		selectedAccounts: userSelectedAccounts = [],
  		images: stateImages = [],
  	} = this.state;

  	const {
  		post: {
  			postDate: editPostDate = '',
  			accountIds = [],
  			images: editPostImages = {},
  			video: editPostVideo = {},
  			pdf: editPostPdf = {},
  			draft = false,
  			postTitle: editPostPostTitle = '',
  			accountsMetadata = [],
  			mediaMetadata = [],
  		} = {},
  		message: editPostMessage = '',
  		accountId: editPostAccountId = '',
  	} = editPost;

  	const postData = postDate || editPostDate;
  	const nowDateUTC = getUTCDateWithSettingTimezone(
  		moment().format('YYYY-MM-DD'),
  		moment()
  			.add({ seconds: 30 })
  			.format('HH:mm'),
  		timezone,
  	);

  	const postDateUTC = getUTCDateWithSettingTimezone(
  		moment(postData)
  			.tz(timezone)
  			.format('YYYY-MM-DD'),
  		moment(postData)
  			.tz(timezone)
  			.format('HH:mm'),
  		timezone,
  	);
  	const isPostPublishedTimeInFuture = moment(nowDateUTC).isBefore(
  		postDateUTC,
  		'seconds',
  	);
  	const postNewDate =
      !isPostPublishedTimeInFuture && draft ? moment() : postDateUTC;

  	const postDateStatus = isPostPublishedTimeInFuture ? 'schedule' : 'now';

  	const buildDate = getNewDateWithoutSettingTimezone(postNewDate, timezone);

  	const postTitle = titleOfPost || editPostPostTitle;
  	const postMessage = message || editPostMessage || '';
  	const postImages = stateImages.length ? stateImages : editPostImages;
  	const selectedAccountIds = userSelectedAccounts.map((account) => account._id);
  	const postAccountsIds = selectedAccountIds.length
  		? selectedAccountIds
  		: accountIds;

  	this.setState(
  		{
  			post: {
  				message: postMessage,
  				postDate: moment(
  					`${buildDate.date} ${buildDate.time}`,
  					'YYYY-MM-DD HH:mm',
  				)
  					.toDate()
  					.toISOString(),
  				accountId: editPostAccountId,
  				accountIds: postAccountsIds,
  				_id: this.props.editPost.event.id,
  				video,
  				pdf,
  				draft: false,
  			},
  			images: postImages,
  			textValue: postMessage,
  			postDateStatus,
  			postTime: buildDate.time,
  			video: editPostVideo,
  			pdf:
          'url' in editPostPdf
          	? { ...editPostPdf, thumbnail: pdfWaveIcon }
          	: {},
  			titleOfPost: postTitle,
  			isAddTitleToGMBPostSelected: !!postTitle,
  			accountsMetadata,
  			mediaMetadata,
  		},
  		() => {
  			const selectedAccounts = this.getArrayAccountsObj(postAccountsIds);
  			this.setState({ selectedAccounts });
  		},
  	);
  };

  setEmojiList = (emojiList) => this.setState({ emojiList });

  getArrayAccountsObj = (arrayIds = []) => {
  	const { accounts } = this.state;
  	const arrayObj = [];

  	if (arrayIds) {
  		arrayIds.map((id) => {
  			const account = accounts.find((item) => item._id === id);
  			if (account) {
  				arrayObj.push(account);
  			}
  			return accounts.find((item) => item._id === id);
  		});
  	}

  	return arrayObj;
  };

  setHashtagsImageUrl = (url) => {
  	this.setState({ hashtagsImageUrl: url });
  };

  setImages = (images) => {
  	this.setState({
  		images,
  	});
  };

  setVideo = (addedVideo) => {
  	this.setState({ video: addedVideo });
  };

  setAudio = (addedVideo) => {
  	this.setState({
  		audio: {
  			...addedVideo,
  			thumbnail: soundWaveIcon,
  		},
  	});
  };

  setPdf = (addedPdf) => {
  	this.setState({
  		pdf: {
  			...addedPdf,
  			thumbnail: pdfWaveIcon,
  		},
  	});
  };

  setImage = (image, file) => {
  	if (!this.state.images.some((item) => item.image === image.image)) {
  		console.log('\nset image', image);
  		this.setState((prevState) => ({
  			images: [
  				...prevState.images,
  				{ image: image.image, id: image.id || image.image, file },
  			],
  		}));
  	}
  };

  setThumbnail = (image) => {
  	this.setState({
  		audioThumbnail: [{ image: image.image, id: image.id }],
  	});
  };

  addSocialImage = (image, ratio) => {
  	const { socialImages } = this.state;
  	const currentImage = socialImages.find((img) => img.name === image.name && img.ratio.rateType === ratio.rateType);
  	console.log('currentImage:', currentImage);

  	if (currentImage) {
  		return this.setState({
  			socialImages: socialImages.filter((img) =>
  					img.name === image.name && img.ratio.rateType !== ratio.rateType),
  		});
  	}

  	this.setState({
  		socialImages: socialImages.concat({
  			ratio,
  			...image,
  		}),
  	});
  };

  getSelectedDates() {
  	const dates = [];
  	const post = this.state.post;

  	if (post.postDate) {
  		dates.push(post.postDate);
  	}

  	return dates;
  }

  getQuote(quote) {
  	this.setState({ addToText: quote });
  }

  getQuotes(quotes) {
  	this.setState({ quotes });
  }

  getLink(link) {
  	this.setState({ link });
  }

  setEmoji = (emoji) => this.setState({ addEmoji: emoji });

  createOrUpdatePost = (isDraft = false) => {
  	const {
  		audioThumbnail,
  		postDateStatus,
  		textValue,
  		audio,
  		video,
  		post,
  		selectedAccounts,
  		images,
  		pdf,
  		isOpenConfirmPostModal,
  		isOpenInstaBusinessPostLimitsModal,
  		// isOpenInstaBusinessMultipleImagesModal,
  		isOpenPinterestMultipleImagesModal,
  		isOpenTwitterPostLimitsModal,
  		// isOpenFacebookGroupIssueModal,
  		postsCount,
  		postTime,
  		callToAction,
  		postingVideoForKids,
  		typeOfPostYoutube,
  		postingVideoShort,
  	} = this.state;

  	const {
  		limits,
  		setIsUpgradeModalShown,
  		setUpgradeModalMetadata,
  	} = this.context;
  	const { user, timezone, editPost } = this.props;

  	const calendar = getCalendar('currentCalendar');
  	const [firstAccount = {}] = selectedAccounts;
  	// const selectedFacebookGroups = selectedAccounts.filter(({ socialMedia, type }) => socialMedia === SocialIntegrationType.FACEBOOK && type === 'group');
  	const selectedInstaBusinessAccount =
      selectedAccounts &&
      selectedAccounts.filter(({ socialMedia, type }) =>
      		socialMedia === SocialIntegrationType.INSTAGRAM &&
          type === SocialIntegrationType.INSTAGRAM_BUSINESS);
  	const isSelectedInstaBusinessAccount = selectedInstaBusinessAccount.length;
  	const selectedTwitterAccount =
      selectedAccounts &&
      selectedAccounts.filter(({ socialMedia }) => socialMedia === SocialIntegrationType.TWITTER);
  	const isSelectedTwitterAccount = selectedTwitterAccount.length;
  	const selectedPinterestAccount =
      selectedAccounts &&
      selectedAccounts.filter(({ socialMedia }) => socialMedia === SocialIntegrationType.PINTEREST);
  	const isSelectedPinterestAccount = selectedPinterestAccount.length;

  	post.message = textValue;
  	post.accountId = firstAccount._id;
  	post.accountIds =
      selectedAccounts && selectedAccounts.map((account) => account._id);
  	post.calendarId = calendar;
  	post.video = video || {};
  	post.audio = audio || {};
  	post.pdf = 'url' in pdf ? { ...pdf, thumbnail: null } : {};
  	post.audioThumbnail = audioThumbnail || {};
  	post.draft = isDraft;

  	if (
  		callToAction &&
      callToAction.url &&
      callToAction.actionType &&
      callToAction.actionType !== 'CALL'
  	) {
  		post.callToAction = callToAction;
  	} else if (
  		callToAction &&
      callToAction.actionType &&
      (callToAction.actionType === 'CALL' ||
        callToAction.actionType === 'OFFER')
  	) {
  		post.callToAction = callToAction;
  	} else {
  		post.callToAction = null;
  	}

  	console.log(post);
  	this.setState({ post });

  	if (video && video.url) {
  		const {
  			thumbnail,
  			url,
  			duration,
  			audioTrack,
  			is4KVideo,
  			videoHeight,
  			videoWidth,
  			videoTimeOffset,
  		} = video;
  		post.video = {};

  		if (Object.keys(video).length) {
  			post.video.url = url;
  			post.video.thumbnail = thumbnail;
  			post.video.duration = duration;
  			post.video.audioTrack = audioTrack;
  			post.video.is4KVideo = is4KVideo;
  			post.video.videoHeight = videoHeight;
  			post.video.videoWidth = videoWidth;
  			post.video.postingVideoShort = postingVideoShort;
  			post.video.postingVideoForKids = postingVideoForKids;
  			post.video.typeOfPostYoutube = typeOfPostYoutube || 'private';
  			post.video.videoTimeOffset = videoTimeOffset || 0;
  		}
  		this.setState({ post });
  	}

  	if (images.length > 0) {
  		const currentImages = images.map((item) => {
  			if (item.image) {
  				return item.image;
  			}
  			return item;
  		});
  		post.images = [...currentImages];
  		this.setState({ post });
  	} else {
  		post.images = [];
  	}

  	const error = this.validatePostData();

  	if (error) {
  		if (error.showUpgradePlanModal) {
  			setUpgradeModalMetadata({
  				reason: subsErrorReasons.no_access,
  				action: error.upgradeModalAction,
  			});
  			return setIsUpgradeModalShown(true);
  		}

  		return this.setState({
  			errorMessage: error,
  		});
  	}

  	// if (selectedFacebookGroups.length && !isOpenFacebookGroupIssueModal && showFacebookIssueModal()) {
  	// 	return this.setState({
  	// 		isOpenFacebookGroupIssueModal: true,
  	// 	});
  	// }

  	if (!isDraft) {
  		if (!isOpenConfirmPostModal && !video.url && !images.length && !pdf.url) {
  			return this.setState({
  				isOpenConfirmPostModal: true,
  				isOpenFacebookGroupIssueModal: false,
  			});
  		}

  		if (images.length) {
  			if (isSelectedInstaBusinessAccount) {
  				// Commented to allow auto posting to Instagram business Carousel
  				// if (images.length > 1 && !isOpenInstaBusinessMultipleImagesModal) {
  				// 	return this.setState({
  				// 		isOpenInstaBusinessMultipleImagesModal: true,
  				// 	});
  				// }
  				if (
  					!this.state.isOpenInstaBusinessGifStaticModal &&
            isGifImage(images[0].image)
  				) {
  					return this.setState({
  						isOpenInstaBusinessGifStaticModal: true,
  					});
  				}
  			}

  			if (isSelectedPinterestAccount) {
  				if (images.length > 1 && !isOpenPinterestMultipleImagesModal) {
  					return this.setState({
  						isOpenPinterestMultipleImagesModal: true,
  					});
  				}
  			}
  		}

  		const postDate = moment(`${moment(post.postDate).format('YYYY-MM-DD')} ${postTime}`)
  			.tz(timezone)
  			.toISOString();
  		if (!isOpenTwitterPostLimitsModal && isSelectedTwitterAccount) {
  			const twitterPostsLimit = UserSubscriptionService.getPlanItemLimit(
  				user.response,
  				limits,
  				'twitterPostPerDay',
  			);

  			let needToShowPostLimitsModal = false;
  			const postDateTwitterPostsCount =
          postsCount.twitterPostsCount[new Date(postDate).toDateString()];

  			if (postDateTwitterPostsCount) {
  				selectedTwitterAccount.forEach((twitterAcc) => {
  					if (
  						!needToShowPostLimitsModal &&
              postDateTwitterPostsCount[twitterAcc.twitterId] >=
                twitterPostsLimit
  					) {
  						needToShowPostLimitsModal = true;
  					}
  				});
  			}

  			if (needToShowPostLimitsModal) {
  				return this.setState({
  					isOpenTwitterPostLimitsModal: true,
  					isOpenInstaBusinessMultipleImagesModal: false,
  					isOpenPinterestMultipleImagesModal: false,
  					isOpenFacebookGroupIssueModal: false,
  					isOpenConfirmPostModal: false,
  				});
  			}
  		}
  		if (
  			!isOpenInstaBusinessPostLimitsModal &&
        isSelectedInstaBusinessAccount
  		) {
  			const isPostDateDayChanged =
          editPost &&
          editPost.event &&
          editPost.event.id &&
          !moment(editPost.event.postDate)
          	.tz(timezone)
          	.isSame(moment(postDate).tz(timezone), 'day');

  			const instagramBusinessAutoPostLimit = UserSubscriptionService.getPlanItemLimit(
  				user.response,
  				limits,
  				'instagramAutoPostLimit',
  			);

  			let needToShowPostLimitsModal = false;
  			const postDateInstagramAutoPostsCount =
          postsCount.instagramAutoPostsCount[new Date(postDate).toDateString()];

  			if (postDateInstagramAutoPostsCount) {
  				selectedInstaBusinessAccount.forEach((instaBusinessAcc) => {
  					if (
  						!needToShowPostLimitsModal &&
              postDateInstagramAutoPostsCount[instaBusinessAcc.instagramId] >=
                instagramBusinessAutoPostLimit
  					) {
  						if (isPostDateDayChanged) {
  							needToShowPostLimitsModal = true;
  						} else if (
  							!editPost ||
                (editPost &&
                  !editPost.event.accounts.find((acc) => acc._id === instaBusinessAcc._id))
  						) {
  							needToShowPostLimitsModal = true;
  						}
  					}
  				});
  			}

  			if (needToShowPostLimitsModal) {
  				return this.setState({
  					isOpenInstaBusinessPostLimitsModal: true,
  					isOpenInstaBusinessMultipleImagesModal: false,
  					isOpenPinterestMultipleImagesModal: false,
  					isOpenFacebookGroupIssueModal: false,
  				});
  			}
  		}
  	}

  	this.setState({
  		isOpenConfirmPostModal: false,
  		isOpenInstaBusinessGifStaticModal: false,
  		isOpenInstaBusinessPostLimitsModal: false,
  		isOpenInstaBusinessMultipleImagesModal: false,
  		isOpenPinterestMultipleImagesModal: false,
  		isOpenFacebookGroupIssueModal: false,
  	});

  	if (postDateStatus === 'now') {
  		delete post.postDate;
  	}

  	this.setState({ post });

  	const isNeedToShowModalForInstallMobileApp = needToShowModalForInstallMobileApp(selectedAccounts);
  	const userNotNeedToRemind = localStorage.getItem('needToRemindInstallMobileApp');
  	const needToShowRemindModal =
      isNeedToShowModalForInstallMobileApp &&
      userNotNeedToRemind !== 'notNeedToRemind' &&
      !post.draft;
  	if (needToShowRemindModal) {
  		return this.setState({ isRemindToInstallWoofyAppModalOpen: true });
  	}

  	return this.savePost();
  };

  validatePostData = () => {
  	const { limits } = this.context;
  	const { timezone, user } = this.props;

  	const {
  		post,
  		postTime,
  		postDateStatus,
  		selectedAccounts,
  		audio,
  		video,
  		pdf,
  		callToAction,
  		accountsMetadata,
  		titleOfPost,
  	} = this.state;

  	const isFacebookSelected = selectedAccounts.some((account) => account.socialMedia === 'facebook');
  	const isInstagramSelected = selectedAccounts.some((account) => account.socialMedia === 'instagram');
  	const isGMBAccountsSelected = isGMBAccountsWereSelected(selectedAccounts);
  	const isGoogleWebStoryAccountSelected = selectedAccounts.some((account) =>
  			(account.socialMedia === SocialIntegrationType.WORDPRESS &&
          account.type === 'story') ||
        (account.socialMedia === SocialIntegrationType.SHOPIFY &&
          account.type === 'story'));

  	const isYoutubeChannelAccountSelected = selectedAccounts.some((account) =>
  			account.socialMedia === SocialIntegrationType.YOUTUBE &&
        account.type === 'channel');

  	const isVideoSelected = Object.keys(this.state.video).length;
  	const isVideo4k = this.state.video.is4KVideo;
  	const isImageSelected = Object.keys(this.state.images).length;
  	const isDurationAvailable = isVideoSelected && this.state.video.duration;
  	const isSizeAvailable = isVideoSelected && this.state.video.size;
  	const sizeMB = 100 * 1024 * 1024;
  	const reelMB = 150 * 1024 * 1024;

  	const isVideoDurationInLimits = isDurationAvailable
  		? this.state.video.duration >= 3 && this.state.video.duration < 60
  		: true;
  	const isVideoSizeInLimits = isSizeAvailable
  		? this.state.video.size < sizeMB
  		: true;
  	const isVideoDurationReelInLimits = isDurationAvailable
  		? this.state.video.duration >= 3 && this.state.video.duration < 90
  		: true;
  	const isVideoSizeReelInLimits = isSizeAvailable
  		? this.state.video.size < reelMB
  		: true;

  	const isSelectedAlexaAccount = selectedAccounts.some(({ socialMedia }) => socialMedia === 'alexaDevice');
  	const isSelectedWhatsAppAccount = selectedAccounts.some(({ socialMedia }) => socialMedia === 'whatsApp');
  	const isSelectedLinkedinAccount = selectedAccounts.some(({ socialMedia }) => socialMedia === 'linkedin');
  	const isSelectedTwitterAccount = selectedAccounts.some(({ socialMedia }) => socialMedia === 'twitter');
  	const isInstaBusinessAccountSelected = selectedAccounts.some(({ socialMedia, type }) =>
  			socialMedia === SocialIntegrationType.INSTAGRAM &&
        type === SocialIntegrationType.INSTAGRAM_BUSINESS);
  	const isPinterestAccountSelected = selectedAccounts.some(({ socialMedia }) => socialMedia === SocialIntegrationType.PINTEREST);
  	const isSnapchatAccountSelected = selectedAccounts.some(({ socialMedia }) => socialMedia === SocialIntegrationType.SNAPCHAT);
  	const isTiktokAccountSelected = selectedAccounts.some(({ socialMedia }) => socialMedia === 'tikTok');
  	const isFeedReminderSelected =
      accountsMetadata.find((tag) => tag.feedReminder) ||
      isSnapchatAccountSelected ||
      isTiktokAccountSelected;
  	const isReelReminderSelected = accountsMetadata.find((tag) => tag.reelReminder);
  	const isStoryReminderSelected = accountsMetadata.find((tag) => tag.storyReminder);
  	const isAutoFeedSelected = accountsMetadata.find((tag) => tag.autoFeed);
  	const isAutoCarouselSelected = accountsMetadata.find((tag) => tag.autoCarousel);
  	const isAutoReelSelected = accountsMetadata.find((tag) => tag.autoReel);

  	let error = '';

  	if (pdf && pdf.url && !isSelectedWhatsAppAccount) {
  		return "Please, choose WhatsApp's social account";
  	}

  	if (audio && audio.url && !isSelectedAlexaAccount) {
  		return "Please, connect your device and choose Alexa's social account";
  	}

  	if (this.state.toggleCategory) {
  		if (this.state.selectedOptions.length === 0) {
  			error = 'Please select a category to save a copy to library';
  		}
  	}

  	if (containsGifImages(post.images)) {
  		if (isSelectedAlexaAccount && isGMBAccountsSelected) {
  			error =
          'Alexa Scheduler and Google My Business Integration does not support GIF files';
  		} else if (isSelectedAlexaAccount) {
  			error = 'Alexa Scheduler does not support GIF files';
  		} else if (isGMBAccountsSelected) {
  			error = 'Google My Business Integration does not support GIF files';
  		} else if (isInstaBusinessAccountSelected) {
  			error = 'Instagram Business supports only images or video';
  		} else if (isInstagramSelected) {
  			error = 'Instagram Personal supports only images or video';
  		}
  	} else if (
  		isSelectedAlexaAccount &&
      post.images &&
      post.images.length > 0 &&
      (!audio || (audio && !audio.url)) &&
      (!video || (video && !video.url))
  	) {
  		error =
        "You cannot create a post for Alexa's social account with only a picture";
  	}

  	if (isVideoSelected && isGMBAccountsSelected) {
  		error =
        "Google My Business doesn't support videos. Remove video file or unselect Google My Business to proceed";
  	}

  	if (isPinterestAccountSelected && !post.images.length) {
  		error = 'Select image for Pinterest or unselect Pinterest to proceed';
  	}

  	if (isGoogleWebStoryAccountSelected && !isImageSelected) {
  		error = 'Post to Google Web Stories should have image or GIF.';
  	}

  	if (isGoogleWebStoryAccountSelected && isVideoSelected) {
  		error = "Post to Google Web Stories doesn't support videos.";
  	}

  	if (isYoutubeChannelAccountSelected && !isVideoSelected) {
  		error = 'Post to YouTube Channel should have video.';
  	}

  	if (!isVideoSelected && !isImageSelected) {
  		if (isInstagramSelected) {
  			error = 'Post to Instagram should have image or video.';
  		}
  	}

  	if (isVideoSelected && isInstagramSelected && isVideo4k) {
  		error = 'You cannot post 4k videos to Instagram.';
  	}

  	if (
  		isFeedReminderSelected &&
      !isTiktokAccountSelected &&
      post.images.length > 1
  	) {
  		error =
        'Select only 1 image for Snapchat Feed Reminder or unselect Feed Reminder to proceed.';
  	}

  	if (
  		isStoryReminderSelected &&
      !isTiktokAccountSelected &&
      post.images.length > 1
  	) {
  		error =
        'Select only 1 image for Snapchat Story Reminder or unselect Story Reminder to proceed.';
  	}

  	if (isFeedReminderSelected && !isTiktokAccountSelected) {
  		const socialMediaData = [];
  		accountsMetadata.forEach((acc) => {
  			if (acc.feedReminder) {
  				const temp = selectedAccounts.find((sel) => acc.accountId === sel._id);
  				if (temp && !socialMediaData.includes(temp.socialMedia)) {
  					socialMediaData.push(temp.socialMedia);
  				}
  			}
  		});
  	}

  	if (isStoryReminderSelected && !isTiktokAccountSelected) {
  		const socialMediaData = [];
  		accountsMetadata.forEach((acc) => {
  			if (acc.storyReminder) {
  				const temp = selectedAccounts.find((sel) => acc.accountId === sel._id);
  				if (temp && !socialMediaData.includes(`${temp.socialMedia}`)) {
  					socialMediaData.push(temp.socialMedia);
  				}
  			}
  		});
  	}

  	if (
  		isInstaBusinessAccountSelected &&
      getHashtagsFromText(post.message).length > 30
  	) {
  		error =
        'You cannot use more than 30 hashtags in Instagram Business post. Please reduce amount of hashtags in message.';
  	}

  	if (
  		isVideoSelected &&
      isInstaBusinessAccountSelected &&
      !isAutoReelSelected &&
      !isAutoCarouselSelected &&
      isAutoFeedSelected
  	) {
  		const aspectRatio = post.video.videoWidth / post.video.videoHeight;
  		// 0.8 and 1.777777 is aspect ration as per 4:5 and 16:9
  		if (aspectRatio < 0.8 || aspectRatio > 1.7777778) {
  			error =
          'The aspect ratio of the video is not suitable for the social network: Instagram Feed. Instagram Feed only supports aspect ratios between 4:5 to 16:9.';
  		}
  	}

  	if (isInstaBusinessAccountSelected && isAutoFeedSelected) {
  		if (post.images && post.images.length > 1) {
  			error = 'Please add 1 image or video for Instagram Feed.';
  		}
  	}

  	if (isInstaBusinessAccountSelected && isAutoCarouselSelected) {
  		if (post.images && post.images.length <= 1) {
  			error = 'Please add atleast 2 or more images for Instagram Carousel.';
  		}
  	}

  	if (isInstaBusinessAccountSelected && isAutoReelSelected) {
  		if (post.images && post.images.length) {
  			error = 'Please add a video for Instagram Reels';
  		}

  		if (
  			post.video &&
        post.video.url &&
        post.video.videoWidth > post.video.videoHeight
  		) {
  			error =
          'Please add a video with vertical aspect ratio, recommended aspect ratio for Instagram Reels is 9:16';
  		}
  	}

  	if (isVideoSelected && isPinterestAccountSelected) {
  		error =
        "Pinterest doesn't support videos. Remove video file or unselect Pinterest to proceed";
  	}

  	if (
  		isVideoSelected &&
      (isInstagramSelected || isInstaBusinessAccountSelected)
  	) {
  		if (video.is4KVideo) {
  			error =
          'You cannot post 4k videos to Instagram. Please change video resolution or unselect Instagram';
  		}
  	}

  	if (!isVideoSelected && isFacebookSelected && isReelReminderSelected) {
  		error =
        'Video is required for Facebook Reel. Please add video or unselect Facebook';
  	}

  	const isLinkedinVideoAllowed = UserSubscriptionService.getPlanItemLimit(
  		user.response,
  		limits,
  		'linkedinVideo',
  	);
  	if (
  		isVideoSelected &&
      isSelectedLinkedinAccount &&
      !isLinkedinVideoAllowed
  	) {
  		error = {
  			showUpgradePlanModal: true,
  			upgradeModalAction: subsActions.addingVideoToLinked,
  		};
  	}

  	if (isImageSelected && isSelectedLinkedinAccount) {
  		if (this.state.images.length > 9) {
  			error =
          'You can only post up to 9 images for LinkedIn. Please select either 9 or less images to proceed.';
  		}
  	}

  	if (isImageSelected && isSelectedTwitterAccount) {
  		if (this.state.images.length > 4) {
  			error =
          'You can only post up to 4 images for Twitter. Please select either 4 or less images to proceed.';
  		}
  	}

  	if (!post.message.trim()) {
  		error = 'Please, provide some content in your awesome post!';
  	} else if (!post.accountIds.length && !post.draft) {
  		error = 'Please select account for post';
  	}

  	if (isSelectedAlexaAccount && post.message.trim()) {
  		const arrayUrls = getArrayOfUrlsFromText(post.message);

  		if (arrayUrls.length > 2) {
  			error = 'Alexa scheduler supports maximum 2 links at a time';
  		}
  	}

  	const nowDate = moment().tz(timezone);
  	if (postDateStatus === 'schedule' && !postTime) {
  		error = 'Please select an hour';
  	}

  	if (postDateStatus === 'schedule' && !post.postDate) {
  		error = 'Please choose a date for the post';
  	} else if (postDateStatus === 'now') {
  		post.postDate = nowDate;
  		this.setState({ post });
  	}

  	if (postDateStatus === 'schedule' && !post.draft) {
  		if (postTime) {
  			const postDateUTC = getUTCDateWithSettingTimezone(
  				moment(post.postDate).format('YYYY-MM-DD'),
  				postTime,
  				timezone,
  			);
  			const nowDateUTC = getUTCDateWithSettingTimezone(
  				getLocalDate(timezone).format('YYYY-MM-DD'),
  				getLocalDate(timezone).format('HH:mm'),
  				timezone,
  			);

  			if (new Date(postDateUTC) < new Date(nowDateUTC)) {
  				error = 'Please, choose time from the future';
  			}
  		}
  	}

  	if (isInstagramSelected) {
  		if (!isVideoDurationInLimits && !isReelReminderSelected) {
  			error =
          'Video duration should be from 3 to 60 seconds if posting to Instagram';
  		}
  		if (isReelReminderSelected && !isVideoDurationReelInLimits) {
  			error =
          'Video duration should be from 3 to 90 seconds if posting to Instagram';
  		}

  		if (!isVideoSizeInLimits && !isReelReminderSelected) {
  			error =
          'Video size must be no more than 100 megabytes when posting to Instagram.';
  		}
  		if (isReelReminderSelected && !isVideoSizeReelInLimits) {
  			error =
          'Video size must be no more than 150 megabytes when posting to Instagram.';
  		}
  	}

  	if (
  		callToAction &&
      callToAction.actionType &&
      callToAction.actionType !== 'CALL' &&
      callToAction.actionType !== 'default' &&
      callToAction.actionType !== 'OFFER'
  	) {
  		let isValidUrl = false;
  		if (!callToAction.url) {
  			error = 'Call to Action Url is required';
  		} else if (
  			callToAction.url.match(urlRegexp).length > 0
  		) {
  			isValidUrl = true;
  		}
  		if (callToAction.url && !isValidUrl) {
  			error =
          'Please correct the Call to Action URL (add https:// and make sure it is a valid URL)';
  		}
  	}

  	if (
  		callToAction &&
      callToAction.actionType &&
      callToAction.url &&
      callToAction.actionType === 'default' &&
      callToAction.actionType !== 'CALL'
  	) {
  		error = 'Please select Call to Action type';
  	}

  	if (isGoogleWebStoryAccountSelected && !titleOfPost) {
  		error = 'Please add title to Your Google Web Stories post';
  	}
  	const isGMBOfferSelected =
      callToAction &&
      callToAction.actionType &&
      callToAction.actionType === 'OFFER';
  	if (isGMBAccountsSelected && isGMBOfferSelected) {
  		if (!titleOfPost) {
  			error = 'Please add title to Your Google My Business Offer post';
  		}
  		if (
  			callToAction.offer &&
        callToAction.offer.redeemOnlineUrl &&
        !callToAction.offer.redeemOnlineUrl.match(urlRegexp).length > 0
  		) {
  			error =
          'Please correct the Call to Action Redeem online url (add https:// and make sure it is a valid URL)';
  		}
  	}
  	if (isYoutubeChannelAccountSelected && !titleOfPost) {
  		error = 'Please add title to Your YouTube Channel post';
  	}

  	return error;
  };

  savePost = () => {
  	const {
  		post,
  		libraryPostId,
  		postDateStatus,
  		postTime,
  		isAddTitleToGMBPostSelected,
  		titleOfPost,
  		accountsMetadata,
  		mediaMetadata,
  		postingVideoForKids,
  		typeOfPostYoutube,
  		postingVideoShort,
  	} = this.state;

  	const {
  		editPost,
  		toggle,
  		onSave,
  		timezone,
  		needToResend,
  		user: { response: userData = {} },
  	} = this.props;

  	if (isAddTitleToGMBPostSelected && titleOfPost.trim().length) {
  		post.postTitle = titleOfPost;
  	}

  	const isUserHaveSubscription = UserSubscriptionService.isUserHaveSubscription(userData);
  	const postType = post.draft ? 'drafts' : 'calendar';

  	const updateState = (newState) => {
  		this.setState({
  			...newState,
  		});
  	};
  	if (editPost && editPost.event.id) {
  		const id = editPost.event.id;
  		updateState({ loader: true });

  		let updatedPost;

  		if (postDateStatus === 'now') {
  			updatedPost = {
  				...post,
  				_id: id,
  				accountsMetadata,
  				mediaMetadata,
  				postingVideoForKids,
  				typeOfPostYoutube,
  				postingVideoShort,
  			};
  		} else {
  			const postDateUTC = getUTCDateWithSettingTimezone(
  				moment(post.postDate).format('YYYY-MM-DD'),
  				postTime,
  				timezone,
  			);
  			updatedPost = {
  				...post,
  				postDate: postDateUTC,
  				_id: id,
  				accountsMetadata,
  				mediaMetadata,
  				postingVideoForKids,
  				typeOfPostYoutube,
  				postingVideoShort,
  			};
  		}

  		updatePost(updatedPost)
  			.then(({ message }) => {
  				toggle(false);
  				this.onToggle();
  				const updatedPostData = {
  					open: true,
  					message,
  					postType,
  					subMessage: '',
  					campaignWarningData: null,
  					postData: {},
  				};
  				onSave(updatedPostData);
  			})
  			.catch((err) => {
  				console.log('\n\n\nerr', err);
  				const {
  					errors = [],
  					message,
  					score = {},
  					postsCount,
  					status,
  					upgradeSubs,
  				} = err;

  				const [firstError] = errors;
  				const errorMessage = firstError ? firstError.msg : message;
  				this.setState({ errorMessage, upgradeSubsBtn: upgradeSubs });

  				if (postsCount && !isUserHaveSubscription) {
  					this.setState({ limitReachedModal: true });
  				}

  				if (score.rejectedRule === 'complianceValidation' && status !== 403) {
  					this.setState({
  						similar: score.maxScore,
  						checkSimilarModal: true,
  						createdPost: updatedPost,
  						similarityScore: {
  							socialMedias: score.socialMedias,
  							library: score.library,
  						},
  						isPostCreate: true,
  					});
  				}
  				if (score.rejectedRule === 'campaignValidation' && status !== 403) {
  					this.setState({
  						createdPost: updatedPost,
  						librarySimilarityModal: true,
  						conflictingCampaignsIds: score.campaigns,
  					});
  				}
  			})
  			.finally(() => {
  				updateState({ loader: false });
  			});
  	} else {
  		updateState({ loader: true });
  		const createdPost = libraryPostId ? { ...post, libraryPostId } : post;
  		if (needToResend) {
  			createdPost.complianceEngineRules = {
  				ignoreComplianceCheck: true,
  				ignoreCampaignCheck: true,
  			};
  		}
  		this.onPostCreate({ ...createdPost, accountsMetadata, mediaMetadata });
  	}
  };

  openModalConfirm = () => {
  	const { closePreviousModal } = this.props;
  	if (closePreviousModal) {
  		closePreviousModal();
  	}
  	this.setState({
  		isOpenConfirmModal: true,
  	});
  };

  openModalConfirmPost = () => {
  	const { closePreviousModal } = this.props;
  	if (closePreviousModal) {
  		closePreviousModal();
  	}
  	this.setState({
  		isOpenConfirmPostModal: true,
  	});
  };

  updateSuggestionHashtags = (suggestionHashtags, _id) => {
  	const hashtagsKeys = Object.keys(suggestionHashtags);
  	const hashtagsKeysTotal = hashtagsKeys.length;
  	if (hashtagsKeysTotal) {
  		const hashtags = [];
  		hashtagsKeys.map((hashtagKey) =>
  			hashtags.push({
  				title: suggestionHashtags[hashtagKey],
  				_id,
  				groupId: _id,
  			}));

  		this.setState({
  			suggestionHashtags: [...new Set(hashtags)].slice(0, 8),
  		});
  	} else if (this.state.suggestionHashtags.length) {
  		const filteredSuggestionHashtags = this.state.suggestionHashtags.filter((suggestionHashtag) => suggestionHashtag.groupId !== _id);

  		this.setState({
  			hashtagsList: filteredSuggestionHashtags,
  			suggestionHashtags: [],
  		});
  	}
  };

  handleSaveDraft = () => {
  	const { timezone, closePreviousModal } = this.props;
  	const { post, postTime } = this.state;

  	if (closePreviousModal) {
  		closePreviousModal();
  	}
  	post.postDate = getUTCDateWithSettingTimezone(
  		moment(post.postDate).format('YYYY-MM-DD'),
  		postTime || '00:00',
  		timezone,
  	);
  	this.setState(
  		{
  			post,
  		},
  		() => this.createOrUpdatePost(true),
  	);
  };

  initPost = (post) => {
  	const { accountId, postDate } = post;
  	const { currentDate } = this.state;

  	this.setState({
  		post: {
  			message: '',
  			postDate: postDate || currentDate,
  			accountId: accountId || null,
  			calendarId: '',
  			draft: false,
  		},
  	});
  };

  changeCurrentPostDate = (value) => {
  	if (value === 'schedule') {
  		const postTime = getDefaultTimeForSchedule();
  		this.setState({
  			postDateStatus: value,
  			showDayPicker: true,
  			postTime,
  		});
  	} else {
  		const post = this.state.post;
  		post.postDate = this.state.currentDate;
  		this.setState({
  			postDateStatus: value,
  			showDayPicker: false,
  			post,
  		});
  	}
  };

  toggleAccount = () => {
  	this.setState(
  		(prevState) => ({
  			dropdownAccountOpen: !prevState.dropdownAccountOpen,
  		}),
  		() => {
  			const { dropdownAccountOpen, accounts } = this.state;
  			const { getAccountsFromDBAction } = this.props;
  			if (dropdownAccountOpen && !accounts.length) {
  				getAccountsFromDBAction(true);
  			}
  		},
  	);
  };

  handleAttachMetadata = (metadata) => {
  	const { mediaMetadata } = this.state;

  	if (metadata.mediaId) {
  		const resultMetadata = mediaMetadata.filter((data) => data.mediaId !== metadata.mediaId);

  		resultMetadata.push(metadata);
  		this.setState({ mediaMetadata: resultMetadata });
  	}
  };

  toggleImageSuggestion = () => {
  	if (this.state.isDisableSuggestingImages) return;

  	this.setState((prevState) => ({
  		imageSuggestion: !prevState.imageSuggestion,
  		messageEditorFocused: !prevState.messageEditorFocused,
  	}));
  };

  toggleDisableSuggestionImages = (isDisabled) => {
  	this.setState({
  		isDisableSuggestingImages: isDisabled,
  	});
  };

  clearAddToText = () => this.setState({ addToText: '' });

  toggleAlertModal = (message) => {
  	const { imageSuggestion, isOpenAlertModal } = this.state;
  	if (imageSuggestion && isOpenAlertModal) {
  		this.setState({
  			imageSuggestion: false,
  		});
  	}

  	this.setState({
  		isOpenAlertModal: !this.state.isOpenAlertModal,
  		alertModalMessage: message,
  	});
  };

  toggleCheckSimilarModal = () => {
  	this.setState({ checkSimilarModal: !this.state.checkSimilarModal });
  };

  toggleSummarizeModal = () => {
  	this.setState({
  		summarizeModal: !this.state.summarizeModal,
  		messageEditorFocused: !this.state.messageEditorFocused,
  	});
  };
  closeLibrarySimilarityModal = () =>
  	this.setState({
  		librarySimilarityModal: !this.state.librarySimilarityModal,
  	});

  changeRemindToInstallWoofyAppModalVisibility = (newValue) => {
  	this.setState({ isRemindToInstallWoofyAppModalOpen: newValue });
  	this.savePost();
  };

  addTextFromImageToPostMessage = (textFormImage) => {
  	const { textValue } = this.state;
  	const newPostMessage = `${textValue} ${textFormImage}`;
  	this.setState({ textValue: newPostMessage });
  };

  changedLinkToLinkWithUTMParams = (linkWithUTM, existingLink) => {
  	const { textValue } = this.state;
  	const newPostMessage = getTextWithUTMParams(
  		textValue,
  		existingLink,
  		linkWithUTM,
  	);
  	this.setState({ textValue: newPostMessage });
  };

  changedMediaFilesLoaderVisibility = (newValue) => {
  	this.setState({ isMediaFilesLoading: newValue });
  };

  changeAddTitleToGMBPostSelected = (newValue) =>
  	this.setState({ isAddTitleToGMBPostSelected: newValue });

  changeGMBPostTitle = (newValue) => this.setState({ titleOfPost: newValue });

  updateExistingCategories = (existingCategories) => {
  	const { updateExistingCategories } = this.props;
  	if (updateExistingCategories) {
  		updateExistingCategories(existingCategories);
  	}
  };

  toggle = () => {
  	this.setState((prevState) => ({
  		dropdownOpen: !prevState.dropdownOpen,
  	}));
  };

  onSelectOption = (categoryId) => {
  	const { post, selectedOptions } = this.state;
  	let newSelectedOptions;
  	const isCategoryAlreadySelected = selectedOptions.some((el) => el === categoryId);
  	if (isCategoryAlreadySelected) {
  		newSelectedOptions = selectedOptions.filter((category) => category !== categoryId);
  	} else {
  		newSelectedOptions = [categoryId];
  	}
  	this.setState({
  		selectedOptions: newSelectedOptions,
  		post: { ...post, categories: newSelectedOptions },
  	});
  	this.toggle();
  };

  toggleOpenIGBModal = () => {
  	this.setState({ openIGBModal: !this.state.openIGBModal });
  };

  onAccountMetadataSelect = (account, smTag) => {
  	const allReminderTags = [
  		'feedReminder',
  		'storyReminder',
  		'personalTimelineReminder',
  		'nonAdminGroupsReminder',
  		'carouselReminder',
  		'reelReminder',
  		'autoFeed',
  		'autoCarousel',
  		'autoReel',
  		'autoStory',
  	];
  	const accountId = account._id;

  	const { accountsMetadata, selectedAccounts } = this.state;

  	const accountMetadata = accountsMetadata.find((accMeta) => accMeta.accountId === accountId);
  	const existingAccount = selectedAccounts.find((selectedAccount) => selectedAccount._id === accountId);

  	let preparedAccountMetadata = {
  		accountId,
  		[smTag]: true,
  	};

  	if (accountMetadata && existingAccount) {
  		if (account.type === 'instagram-business' && Object.keys(accountMetadata).length > 1) {
  			if (
  				Object.prototype.hasOwnProperty.call(accountMetadata, 'autoFeed') &&
          		smTag === 'autoFeed'
  			) {
  				preparedAccountMetadata = {
  					...accountMetadata,
  					[smTag]: !accountMetadata[smTag],
  				};
  			} else if (
  				Object.prototype.hasOwnProperty.call(
  					accountMetadata,
  					'autoCarousel',
  				) &&
				smTag === 'autoCarousel'
  			) {
  				preparedAccountMetadata = {
  					...accountMetadata,
  					[smTag]: !accountMetadata[smTag],
  				};
  			} else if (
  				Object.prototype.hasOwnProperty.call(accountMetadata, 'autoReel') &&
          		smTag === 'autoReel'
  			) {
  				preparedAccountMetadata = {
  					...accountMetadata,
  					[smTag]: !accountMetadata[smTag],
  				};
  			} else if (
  				Object.prototype.hasOwnProperty.call(accountMetadata, 'autoStory') &&
				smTag === 'autoStory'
  			) {
  				preparedAccountMetadata = {
  					...accountMetadata,
  					[smTag]: !accountMetadata[smTag],
  				};
  			} else if (Object.keys(accountMetadata).length === 2) {
  				return this.toggleOpenIGBModal();
  			}
  		}
  		preparedAccountMetadata = {
  			...accountMetadata,
  			[smTag]: !accountMetadata[smTag],
  		};
  	}

  	let removeAccount = true;

  	allReminderTags.forEach((tag) => {
  		if (preparedAccountMetadata[tag]) {
  			removeAccount = false;
  		}
  	});

  	const resultAccountsMetadata = accountsMetadata.filter((data) => data.accountId !== accountId);
  	if (!removeAccount) {
  		resultAccountsMetadata.push(preparedAccountMetadata);
  	}

  	if (removeAccount || !existingAccount) {
  		this.onSelectAccount(account);
  	}
  	this.setState({
  		accountsMetadata: resultAccountsMetadata,
  	});
  };

  setVideoForKids = (postingVideoForKids) => {
  	this.setState({ postingVideoForKids });
  };

  setVideoShort = (postingVideoShort) => {
  	this.setState({ postingVideoShort });
  };

  getFileListToDelete = () => {
  	const calendar = getCalendar();
  	if (
  		this.props.location.pathname !== `/${calendar}/` ||
      this.state.existingPost
  	) {
  		return [];
  	}
  	let filesToDelete = [];
  	const imagesToDelete = [];
  	const videoFilesToDelete = [];
  	const audioFilesToDelete = [];
  	this.state.images.map((image) => {
  		if (
  			typeof image === 'object' &&
        image.image.indexOf('storage.googleapis.com') >= 0
  		) {
  			imagesToDelete.push(image.image.split('/').reverse()[0]);
  		} else if (
  			typeof image === 'object' &&
        image.image.indexOf('storage.googleapis.com') < 0
  		) {
  			return null;
  		} else if (
  			typeof image === 'string' &&
        image.indexOf('storage.googleapis.com') >= 0
  		) {
  			imagesToDelete.push(image.split('/').reverse()[0]);
  		}
  		return null;
  	});
  	if (
  		this.state.video &&
      this.state.video.url &&
      this.state.video.url.indexOf('storage.googleapis.com') >= 0
  	) {
  		videoFilesToDelete.push(this.state.video.url.split('/').reverse()[0]);
  		videoFilesToDelete.push(this.state.video.audioTrack.split('/').reverse()[0]);
  		videoFilesToDelete.push(this.state.video.thumbnail.split('/').reverse()[0]);
  	}

  	if (
  		this.state.audio &&
      this.state.audio.url &&
      this.state.audio.url.indexOf('storage.googleapis.com') >= 0
  	) {
  		audioFilesToDelete.push(this.state.audio.url.split('/').reverse()[0]);
  	}
  	filesToDelete = filesToDelete.concat(imagesToDelete);
  	filesToDelete = filesToDelete.concat(videoFilesToDelete);
  	filesToDelete = filesToDelete.concat(audioFilesToDelete);
  	filesToDelete = filesToDelete.filter((i) => i); // remove any null values
  	return filesToDelete;
  };

  handleOpenCaptionGenerationModal = () => {
  	this.setState({
  		openCaptionGenerationModal: true,
  	});
  };

  render() {
  	const {
  		images,
  		video,
  		audio,
  		pdf,
  		similar,
  		checkSimilarModal,
  		selectedAccounts,
  		discoverPostImage,
  		unmounted,
  		createdPost,
  		imageSuggestion,
  		similarityScore,
  		isPostCreate,
  		emojiList,
  		addEmoji,
  		emojiCount,
  		librarySimilarityModal,
  		conflictingCampaignsIds,
  		post,
  		currentDate,
  		textValue,
  		closeExtModal,
  		isRemindToInstallWoofyAppModalOpen,
  		hashtagsImageUrl,
  		postDateStatus,
  		showDayPicker,
  		isMediaFilesLoading,
  		postsCount,
  		socialImages,
  		imgRatioValidate,
  		mediaMetadata,
  		isAddTitleToGMBPostSelected,
  		titleOfPost,
  		accounts,
  		postingVideoForKids,
  		postingVideoShort,
  		callToAction,
  		showChatGptIconInTitleSec,
  		openCaptionGenerationModal,
  	} = this.state;

  	const {
  		limits,
  		setIsUpgradeModalShown,
  		setUpgradeModalMetadata,
  	} = this.context;
  	const {
  		libraryPost, timezone, user, showSaveToLibrary,
  	} = this.props;

  	const confirmationButtonText =
      postDateStatus === 'now' ? 'Post' : 'Schedule';

  	const isAdvancedArticleSummarizerAvailable = UserSubscriptionService.getPlanItemLimit(
  		user.response,
  		limits,
  		'advancedArticleSummarizer',
  	);
  	const headerModalTitle = imageSuggestion
  		? 'Select Suggested Images'
  		: 'Graphic Design Tool';
  	const headerModalContent = (
  		<div>
  			<img src={postIcon} alt="Graphic Design Tool" />
  			{headerModalTitle}
  		</div>
  	);

  	const userCurrentTimeZone = moment()
  		.tz(timezone)
  		.format('Z');

  	const selectedTwitterAccounts =
      selectedAccounts &&
      selectedAccounts.filter(({ socialMedia }) => socialMedia === SocialIntegrationType.TWITTER);
  	const selectedYoutubeAccounts =
      selectedAccounts &&
      selectedAccounts.filter(({ socialMedia }) => socialMedia === SocialIntegrationType.YOUTUBE);

  	// const userSubscriptionPlan = UserSubscriptionService.getUserSubscriptionPlan(user.response);
  	// const isBannerBearAllowed = limits[userSubscriptionPlan].bannerBearImagesPerMonth > 0 || false;

  	return (
  		<Fragment>
  			<Modal
  				isOpen={this.props.isOpen}
  				toggle={this.openModalConfirm}
  				size="lg"
  				className="modal-calendar-post"
  				centered
  			>
  				<ModalHeader toggle={this.openModalConfirm}>
  					<div className="modal-calendar-post-header">
  						<img src={postIcon} alt="post" />
  						{this.props.editPost ? 'Edit Post' : 'Create New Post'}{' '}
  						<PostLimitsWarningTooltip />
  					</div>
  					{showDayPicker && (
  						<div id="modal-header-picker">
  							<CalendarMonthPicker
  								onChangeMonth={this.onChangeMonth}
  								currentMonth={this.state.currentMonth}
  								patternSchedule={false}
  							/>
  						</div>
  					)}
  					<ShowingVideoGuideButton url="https://player.vimeo.com/video/393782626" />
  				</ModalHeader>

  				<ModalBody className={showDayPicker ? 'day-picker' : ''}>
  					{showDayPicker ? (
  						<div className="campaign-day-picker">
  							<CalendarNavArrows
  								onChangeDate={this.onChangeMonth}
  								type="month"
  								currentMonth={this.state.currentMonth}
  							/>
  							<CalendarMonth
  								onDayClick={this.onSelectDates}
  								selectedDates={this.getSelectedDates()}
  								selectMode="single"
  								minDate={getLocalDate(timezone, moment(currentDate)).toDate()}
  								currentMonth={this.state.currentMonth}
  								onChangeMonth={this.onChangeMonth}
  								timezone={timezone}
  								isDayPickerShowing
  							/>
  						</div>
  					) : (
  						<Form>
  							<FormGroup tag="fieldset">
  								<FormGroup>
  									<Alert
  										className={cx({
  											'hidden-alert': !this.state.errorMessage,
  										})}
  										color="danger"
  										toggle={() => this.setState({ errorMessage: '' })}
  										style={
  											this.state.upgradeSubsBtn
  												? {
  													display: this.state.errorMessage
  														? 'flex'
  														: 'none',
  												}
  												: {}
  										}
  									>
  										<div
  											style={
  												this.state.upgradeSubsBtn
  													? {
  														margin: 'auto 0',
  														paddingRight: 16,
  													}
  													: {}
  											}
  											ref={this.errorMessageRef}
  											dangerouslySetInnerHTML={{
  												__html: this.state.errorMessage || (
  													<span>&nbsp;</span>
  												),
  											}}
  										/>
  										{this.state.upgradeSubsBtn && (
  											<UpgradeNowButton inNewTab />
  										)}
  									</Alert>
  									<div className="input-field">
  										<div className="header-translator">
  											<Label className="header-label">
                          What do you want to say?
  											</Label>
  											<LanguageTranslator
  												text={textValue}
  												setTranslatedText={this.onMessageChange}
  											/>
  										</div>
  										<AddTitleToGMBPost
  											isAddTitleToGMBPostSelected={
  												isAddTitleToGMBPostSelected
  											}
  											changeAddTitleToGMBPostSelected={
  												this.changeAddTitleToGMBPostSelected
  											}
  											titleOfPost={titleOfPost}
  											changeGMBPostTitle={this.changeGMBPostTitle}
  											userAccounts={accounts}
  											showChatGptIconInTitleSec={showChatGptIconInTitleSec}
  											handleTitleGeneration={this.handleTitleGeneration}
  										/>
  										<MessageEditor
  											focused={this.state.messageEditorFocused}
  											message={textValue}
  											onChange={this.onMessageChange}
  											addToText={this.state.addToText}
  											clearAddToText={this.clearAddToText}
  											updateSuggestionHashtags={this.updateSuggestionHashtags}
  											setEmojiList={this.setEmojiList}
  											addEmoji={addEmoji}
  											setEmoji={this.setEmoji}
  											emojiCount={emojiCount}
  											user={user}
  											limits={limits}
  											handleAppendCaption={this.handleAppendCaption}
  											handleOpenCaptionGenerationModal={
  												this.handleOpenCaptionGenerationModal
  											}
  										/>
  										<TextMessageCounter text={textValue} />
  									</div>
  								</FormGroup>
  								<div className="create-post-add-utm-container">
  									<AddUTMParamsToLink
  										messageText={textValue}
  										changedLinkToLinkWithUTMParams={
  											this.changedLinkToLinkWithUTMParams
  										}
  									/>
  								</div>
  								<div className="quotes-container">
  									<Label style={{ marginTop: 13 }}>Quotes</Label>
  									<Button
  										style={{ marginBottom: 7 }}
  										color="primary"
  										onClick={() => {
  											if (!isAdvancedArticleSummarizerAvailable) {
  												setUpgradeModalMetadata({
  													reason: subsErrorReasons.no_access,
  													action: subsActions.advancedArticleSummarizer,
  												});
  												return setIsUpgradeModalShown(true);
  											}
  											this.toggleSummarizeModal();
  										}}
  									>
                      Summarize Article or Paragraph
  									</Button>
  								</div>
  								<Quotes
  									quotes={this.state.quotes}
  									link={this.state.link}
  									onAddQuote={this.getQuote}
  								/>
  								<FormGroup>
  									<div className="hashtags-title-container">
  										<Label>
                        Hashtags (Please Click or Tap Hashtags Below to Add to
                        Your Post)
  										</Label>
  										<ManageHashtags
  											changeHashtagList={this.onChangeHashtagList}
  											detectedFromURL={this.state.link}
  											suggestionHashtags={this.state.suggestionHashtags}
  										/>
  									</div>
  									<CalendarContentPicker
  										onSelect={this.onPostValueChange}
  										hashtags={getHashtagsFromText(textValue)}
  										mode="link"
  										link={this.state.links}
  										getQuotes={this.getQuotes}
  										getLink={this.getLink}
  										listOfHashtags={this.state.listOfHashtags}
  										hashtagsList={this.state.hashtagsList}
  									/>
  								</FormGroup>
  								<FormGroup>
  									<Label>Emojis</Label>
  									<React.Suspense fallback={<Loader />}>
  										<ManageEmojis
  											setEmoji={this.setEmoji}
  											emojiList={emojiList}
  										/>
  									</React.Suspense>
  								</FormGroup>
  								<FormGroup>
  									<div className="d-flex flex-column">
  										<Label>
  											{callToAction &&
												callToAction.actionType &&
												callToAction.actionType === 'OFFER'
  												? 'Add offer details for GMB'
  												: 'Add link / Call to action ( IG story, GMB, Alexa and Pinterest only )'}
  										</Label>
  										<ManageCTA
  											setCallToAction={(cta) => {
  												this.setState({
  													callToAction: {
  														url: cta.actionType === 'CALL' ? '' : cta.url,
  														offer: cta.offer,
  														actionType: cta.actionType,
  													},
  												});
  											}}
  											timezone={timezone}
  											postDate={
  												this.state.postTime
  													? getUTCDateWithSettingTimezone(
  														moment(post.postDate).format('YYYY-MM-DD'),
  														this.state.postTime,
  														timezone,
  													)
  													: post.postDate
  											}
  											isEditPost={
  												this.props.editPost || {
  													event: { post: this.props.libraryPost },
  												}
  											}
  										/>
  									</div>
  								</FormGroup>
  							</FormGroup>

  							<FormGroup tag="fieldset">
  								<Row>
  									<Col className="calendar-post-modal-body-bottom-column">
  										<FormGroup>
  											<Label>Images Related Hashtags</Label>
  											<CalendarContentPicker
  												onSelect={this.onPostValueChange}
  												hashtags={getHashtagsFromText(textValue)}
  												mode="img"
  												img={hashtagsImageUrl}
  											/>
  										</FormGroup>
  										<FormGroup className="post-date-options">
  											<div className="inputs-container">
  												<Label>Post Date</Label>
  												<input type="hidden" value={postDateStatus} />
  												<CustomInput
  													onClick={() => this.changeCurrentPostDate('now')}
  													type="radio"
  													id="postType1"
  													name="postType"
  													label="Post Now"
  													checked={postDateStatus === 'now'}
  													readOnly
  												/>
  												<div className="post-date-box">
  													<CustomInput
  														onClick={() =>
  															this.changeCurrentPostDate('schedule')
  														}
  														type="radio"
  														id="postType2"
  														name="postType"
  														label="Schedule Post"
  														checked={postDateStatus === 'schedule'}
  														readOnly
  													/>
  													{postDateStatus === 'schedule' && (
  														<div className="post-date-shedule">
  															<Label>Schedule Date: </Label>
  															<span
  																className="post-date"
  																{...clickable(() =>
  																	this.changeCurrentPostDate('schedule'))}
  															>
  																{moment(post.postDate)
  																	.tz(timezone)
  																	.format('MM/DD')}
  															</span>
  														</div>
  													)}
  												</div>
  											</div>
  											<div className="dates-container">
  												<div className="date-labels-container">
  													<Label>Select hour</Label>
  													<Label className="date-labels-container-current-TZ">
                              Your current GM {userCurrentTimeZone}
  													</Label>
  												</div>
  												<CustomInput
  													disabled={postDateStatus === 'now'}
  													className="drop-down-pointer"
  													id="select-hour"
  													type="select"
  													onChange={this.onPostTimeChange}
  													value={
  														this.state.postTime ? this.state.postTime : ''
  													}
  													readOnly
  												>
  													{renderHourSelect(timezone)}
  												</CustomInput>
  											</div>
  										</FormGroup>
  										{selectedYoutubeAccounts.length !== 0 && 'url' in video && (
  											<FormGroup>
  												<Row>
  													<Col className="youtube-post-modal">
  														<div>
  															<Label className="label-youtube">
                                  Audience for YouTube
  															</Label>
  															<img
  																src={postWarningTooltip}
  																alt="youtube-warning-tooltip"
  																className="warning-tooltip-icon"
  																data-for="youtube-warning"
  																data-tip=""
  															/>
  															<ReactTooltip
  																id="youtube-warning"
  																className="post-campaign-warning-tooltip"
  																place="bottom"
  																effect="solid"
  																backgroundColor="#07a3ed"
  																getContent={() => (
  																	<div>
                                      This video is set to not made for kids
  																	</div>
  																)}
  																delayHide={300}
  																clickable
  															/>
  														</div>
  														<CustomInput
  															onClick={() => this.setVideoForKids(true)}
  															id="youtube-select"
  															name="youtube"
  															type="radio"
  															label="Yes, it's made for kids."
  															checked={postingVideoForKids}
  														/>
  														<CustomInput
  															onClick={() => this.setVideoForKids(false)}
  															id="youtube-select-no"
  															name="youtube"
  															type="radio"
  															label="No, it's not made for kids."
  															checked={!postingVideoForKids}
  														/>
  													</Col>
  													<Col className="youtube-post-modal-select">
  														<div className="date-labels-container">
  															<Label>
                                  Select visibility video on YouTube
  															</Label>
  														</div>
  														<CustomInput
  															className="drop-down-pointer"
  															id="select-hour"
  															type="select"
  															onChange={this.onPostVideoYoutubeChange}
  															value={
  																this.state.typeOfPostYoutube
  																	? this.state.typeOfPostYoutube
  																	: ''
  															}
  															readOnly
  														>
  															<Fragment>
  																<option value="public">Public</option>
  																<option value="private">Private</option>
  																<option value="unlisted">Unlisted</option>
  															</Fragment>
  														</CustomInput>
  													</Col>
  												</Row>
  												{video.videoHeight >= video.videoWidth &&
                            video.duration < 61 && (
  													<Row>
  														<Col>
  															<div>
  																<Label className="label-youtube">
                                      Type of video shorts ?
  																</Label>
  																<img
  																	src={postWarningTooltip}
  																	alt="youtube-warning-tooltip"
  																	className="warning-tooltip-icon"
  																	data-for="youtube-shorts-warning"
  																	data-tip=""
  																/>
  																<ReactTooltip
  																	id="youtube-shorts-warning"
  																	className="post-youtube-shorts-warning-tooltip"
  																	place="bottom"
  																	effect="solid"
  																	backgroundColor="#07a3ed"
  																	getContent={() => (
  																		<div>
                                          YouTube itself determines what type of
                                          video, this is necessary for a better
                                          understanding of YouTube what type of
                                          video this is
  																		</div>
  																	)}
  																	delayHide={300}
  																	clickable
  																/>
  															</div>
  															<CustomInput
  																onClick={() => this.setVideoShort(true)}
  																id="youtube-select-shorts"
  																name="youtube-short"
  																type="radio"
  																label="Yes"
  																checked={postingVideoShort}
  															/>
  															<CustomInput
  																onClick={() => this.setVideoShort(false)}
  																id="youtube-select-shorts-no"
  																name="youtube-short"
  																type="radio"
  																label="No"
  																checked={!postingVideoShort}
  															/>
  														</Col>
  													</Row>
  												)}
  											</FormGroup>
  										)}
  									</Col>
  									<Col>
  										<FormGroup>
  											<CalendarImageUploader
  												className="add-content-uploader"
  												selectedAccounts={this.state.selectedAccounts}
  												onRemove={this.onRemove}
  												discoverPostImage={discoverPostImage}
  												images={images}
  												imageSuggestion={this.state.imageSuggestion}
  												addSocialImage={this.addSocialImage}
  												socialImages={socialImages}
  												setImage={this.setImage}
  												setImages={this.setImages}
  												imgRatioValidate={imgRatioValidate}
  												video={video}
  												setVideo={this.setVideo}
  												audio={audio}
  												mediaMetadata={mediaMetadata}
  												setAudio={this.setAudio}
  												pdf={pdf}
  												setPdf={this.setPdf}
  												handleAttachMetadata={this.handleAttachMetadata}
  												unmounted={unmounted}
  												toggleDisableSuggestionImages={
  													this.toggleDisableSuggestionImages
  												}
  												toggleAlertModal={this.toggleAlertModal}
  												setHashtagsImageUrl={this.setHashtagsImageUrl}
  												setEmojiList={this.setEmojiList}
  												emojiCount={emojiCount}
  												toggleImageSuggestion={this.toggleImageSuggestion}
  												message={textValue}
  												addTextFromImageToPostMessage={
  													this.addTextFromImageToPostMessage
  												}
  												isMediaFilesLoading={isMediaFilesLoading}
  												changedMediaFilesLoaderVisibility={
  													this.changedMediaFilesLoaderVisibility
  												}
  												links={this.state.links}
  												// showBannerBear={isBannerBearAllowed}
  												showBannerBear={false}
  												setUpgradeModalMetadata={setUpgradeModalMetadata}
  												setIsUpgradeModalShown={setIsUpgradeModalShown}
  												handleGeneratePostFromVideo={this.handleGeneratePostFromVideo}
  											/>
  										</FormGroup>
  									</Col>
  								</Row>
  							</FormGroup>
  						</Form>
  					)}
  				</ModalBody>
  				<ModalFooter>
  					<div>
  						{!showDayPicker && (
  							<div className="account-picker">
  								<DropdownSelectAccount
  									accounts={this.state.accounts}
  									selectedOptions={this.state.selectedAccounts}
  									onSelectOption={this.onSelectAccount}
  									dropdownOpen={this.state.dropdownAccountOpen}
  									accountsMetadata={this.state.accountsMetadata}
  									onAccountMetadataSelect={this.onAccountMetadataSelect}
  									toggle={this.toggleAccount}
  									width="408px"
  									height="40px"
  									marginTop="0"
  								/>
  							</div>
  						)}
  					</div>

  					<div className="submit-buttons">
  						<Button
  							color="light"
  							onClick={() => {
  								const filesToDelete = this.getFileListToDelete();
  								if (filesToDelete && filesToDelete.length > 0) {
  									deleteFiles(filesToDelete)
  										.then(() => {
  											this.onToggle();
  										})
  										.catch((err) => {
  											console.log(err);
  											this.onToggle();
  										});
  								} else {
  									this.onToggle();
  								}
  							}}
  						>
                Cancel
  						</Button>
  						{this.state.showDayPicker ? (
  							<Button color="primary" onClick={this.onNext}>
                  Next
  							</Button>
  						) : (
  							<div>
  								<Button
  									color="light"
  									onClick={this.handleSaveDraft}
  									disabled={isMediaFilesLoading}
  								>
                    Save draft
  								</Button>
  								<Button
  									color="primary"
  									onClick={() => this.createOrUpdatePost()}
  									disabled={isMediaFilesLoading}
  								>
  									{confirmationButtonText}
  								</Button>
  							</div>
  						)}
  					</div>
  					{showSaveToLibrary && !showDayPicker && (
  						<div style={{ width: '100%', marginLeft: '1rem' }}>
  							{!this.props.notShowSaveToLibrary && (
  								<CustomInput
  									onChange={() =>
  										this.setState({
  											toggleCategory: !this.state.toggleCategory,
  										})
  									}
  									type="checkbox"
  									id="selectCategoryCheckbox"
  									name="selectCategoryCheckbox"
  									label="Save to Library"
  									checked={this.state.toggleCategory}
  									value={this.state.toggleCategory}
  								/>
  							)}
  							{this.state.toggleCategory && (
  								<FormGroup className="post-date-options category-select">
  									<Label>Category</Label>
  									<DropdownSelectCategory
  										selectedOptions={
  											this.state.selectedOptions.length
  												? this.state.selectedOptions
  												: this.state.post.categories
  										}
  										updateExistingCategories={this.updateExistingCategories}
  										onSelectOption={this.onSelectOption}
  										dropdownOpen={this.state.dropdownOpen}
  										toggle={this.toggle}
  										placeholder="Choose Category"
  									/>
  								</FormGroup>
  							)}
  						</div>
  					)}
  				</ModalFooter>
  			</Modal>

  			{checkSimilarModal && (
  				<SimilarityModal
  					onPostCreate={this.onPostCreate}
  					accounts={selectedAccounts}
  					createdPost={createdPost}
  					isOpen={checkSimilarModal}
  					toggle={this.toggleCheckSimilarModal}
  					similar={similar}
  					isTwitter={selectedAccounts.find((account) => account.socialMedia === 'twitter')}
  					similarityScore={similarityScore}
  					isPostCreate={isPostCreate}
  					isCalendar
  				/>
  			)}
  			<ModalLoader
  				message="Your post is being processed right now. Please do not close this window"
  				isOpen={this.state.loader}
  			/>

  			<CalendarSummarizeModal
  				toggle={this.toggleSummarizeModal}
  				isOpen={this.state.summarizeModal}
  				onSelect={this.onPostValueChange}
  				user={user}
  				limits={limits}
  			/>

  			<AlertModal
  				toggle={this.toggleAlertModal}
  				isOpen={this.state.isOpenAlertModal}
  				message={this.state.alertModalMessage}
  				contentHeader={headerModalContent}
  			/>

  			<ConfirmModal
  				isOpen={this.state.isOpenConfirmModal}
  				onConfirm={() => {
  					const filesToDelete = this.getFileListToDelete();
  					if (filesToDelete && filesToDelete.length > 0) {
  						deleteFiles(filesToDelete)
  							.then(() => {
  								this.onToggle();
  								if (closeExtModal) closeExtModal();
  							})
  							.catch((err) => {
  								console.log(err);
  								this.onToggle();
  								if (closeExtModal) closeExtModal();
  							});
  					} else {
  						this.onToggle();
  						if (closeExtModal) closeExtModal();
  					}
  				}}
  				onClose={() => {
  					this.setState({ isOpenConfirmModal: false });
  				}}
  				message={woofyText.unsavedChanges}
  			/>
  			<ConfirmModal
  				isOpen={this.state.isOpenInstaBusinessGifStaticModal}
  				onConfirm={() => {
  					if (closeExtModal) closeExtModal();
  					this.createOrUpdatePost();
  				}}
  				onClose={() => {
  					this.setState({ isOpenInstaBusinessGifStaticModal: false });
  				}}
  				message={woofyText.instaBusinessGifStatic}
  			/>
  			<ConfirmModal
  				isOpen={this.state.isOpenInstaBusinessMultipleImagesModal}
  				onConfirm={() => {
  					if (closeExtModal) closeExtModal();
  					this.createOrUpdatePost();
  				}}
  				onClose={() => {
  					this.setState({ isOpenInstaBusinessMultipleImagesModal: false });
  				}}
  				message={woofyText.instaBusinessMultipleImagesLimit}
  			/>
  			<ConfirmModal
  				isOpen={this.state.isOpenPinterestMultipleImagesModal}
  				onConfirm={() => {
  					if (closeExtModal) closeExtModal();
  					this.createOrUpdatePost();
  				}}
  				onClose={() => {
  					this.setState({ isOpenPinterestMultipleImagesModal: false });
  				}}
  				message={woofyText.pinterestMultipleImagesLimit}
  			/>
  			<ConfirmModal
  				isOpen={this.state.isOpenFacebookGroupIssueModal}
  				onConfirm={() => {
  					if (closeExtModal) closeExtModal();
  					this.createOrUpdatePost();
  				}}
  				onClose={() => {
  					this.setState({ isOpenFacebookGroupIssueModal: false });
  				}}
  				rejectActionText="Edit"
  				confirmActionText="Post anyway"
  				message={
  					<div style={{ textAlign: 'left' }}>
  						<div>
  							<span>
                  Dear users, we want to notify you that Facebook has issue with
                  posting to <b>Facebook groups</b> since <b>19 May</b>. For
                  that reason you may face issues with posting links and images
                  to <b>Facebook groups</b>.
  							</span>{' '}
                You can find more details{' '}
  							<a
  								rel="noopener noreferrer"
  								target="_blank"
  								href="https://developers.facebook.com/support/bugs/179222977434163/?join_id=f12df9b3ec7cf0c"
  							>
                  here
  							</a>
  						</div>
  						<div>
  							<span>
                  We are trying to find workaround, here is some tips, which may
                  help:
  							</span>
  						</div>
  						<div> • Try to put link in the end of your message</div>
  						<div>
  							{' '}
                • Try to post without image, in this case it will take image
                from your link{' '}
  						</div>
  						<div>
  							{' '}
                • Try to check words which in your message. In some cases it
                block particular words like Shop or SEO, unfortunately we do not
                have full list{' '}
  						</div>
  					</div>
  				}
  			/>

  			<ConfirmModal
  				isOpen={this.state.isOpenConfirmPostModal}
  				onConfirm={() => {
  					if (closeExtModal) closeExtModal();
  					this.createOrUpdatePost();
  				}}
  				onClose={() => {
  					this.setState({ isOpenConfirmPostModal: false });
  				}}
  				message={woofyText.sendPostWithoutMedia}
  			/>

  			<DailyLimitsModal
  				isOpen={this.state.isOpenInstaBusinessPostLimitsModal}
  				selectedAccounts={selectedAccounts}
  				instagramAutoPostsCount={postsCount.instagramAutoPostsCount}
  				instagramBusinessAutoPostLimit={UserSubscriptionService.getPlanItemLimit(
  					user.response,
  					limits,
  					'instagramAutoPostLimit',
  				)}
  				postDates={[post.postDate]}
  				onConfirm={() => {
  					if (closeExtModal) closeExtModal();
  					this.createOrUpdatePost();
  				}}
  				onClose={() => {
  					this.setState({
  						isOpenInstaBusinessPostLimitsModal: false,
  						isOpenInstaBusinessGifStaticModal: false,
  					});
  				}}
  			/>

  			<DailyLimitsModal
  				isOpen={this.state.isOpenTwitterPostLimitsModal}
  				selectedAccounts={selectedTwitterAccounts}
  				postsCount={postsCount.twitterPostsCount}
  				postLimit={UserSubscriptionService.getPlanItemLimit(
  					user.response,
  					limits,
  					'twitterPostPerDay',
  				)}
  				postDates={[post.postDate]}
  				withReminderText={false}
  				onConfirmText="UPGRADE NOW"
  				rejectActionText="Cancel"
  				onConfirm={
  					() => window.open('/upgrade-profile', '_blank')
  					// this.onSelectAccount(selectedTwitterAccounts[0], false)
  					// if (closeExtModal) closeExtModal();
  					// this.setState({
  					// 	isOpenTwitterPostLimitsModal: false,
  					// 	isOpenInstaBusinessGifStaticModal: false,
  					// });
  					// todo unselect
  				}
  				onClose={() => {
  					this.setState({
  						isOpenTwitterPostLimitsModal: false,
  						isOpenInstaBusinessGifStaticModal: false,
  					});
  				}}
  			/>

  			<DailyLimitsModal
  				isOpen={this.state.isOpenInstaBusinessPostLimitsModal}
  				selectedAccounts={
  					selectedAccounts &&
            selectedAccounts.filter(({ socialMedia, type }) =>
            		socialMedia === SocialIntegrationType.INSTAGRAM &&
                type === SocialIntegrationType.INSTAGRAM_BUSINESS)
  				}
  				postsCount={postsCount.instagramAutoPostsCount}
  				postLimit={UserSubscriptionService.getPlanItemLimit(
  					user.response,
  					limits,
  					'instagramAutoPostLimit',
  				)}
  				postDates={[post.postDate]}
  				timezone={timezone}
  				onConfirm={() => {
  					if (closeExtModal) closeExtModal();
  					this.createOrUpdatePost();
  				}}
  				onClose={() => {
  					this.setState({
  						isOpenInstaBusinessPostLimitsModal: false,
  						isOpenInstaBusinessGifStaticModal: false,
  					});
  				}}
  			/>

  			<LibrarySimilarityModal
  				isOpen={librarySimilarityModal}
  				toggle={this.closeLibrarySimilarityModal}
  				libraryPost={libraryPost}
  				conflictingCampaignsIds={conflictingCampaignsIds}
  				onPostCreate={this.onPostCreate}
  				createdPost={createdPost}
  			/>

  			<ConfirmModal
  				isOpen={this.state.limitReachedModal}
  				onClose={() => {
  					this.setState({ limitReachedModal: false });
  				}}
  				onConfirm={() => {
  					this.onRedirect('upgrade-profile');
  				}}
  				rejectActionText="Cancel"
  				confirmActionText="Upgrade"
  				message={this.state.errorMessage}
  			/>

  			<RemindToInstallWoofyAppModal
  				isRemindToInstallWoofyAppModalOpen={
  					isRemindToInstallWoofyAppModalOpen
  				}
  				changeRemindToInstallWoofyAppModalVisibility={
  					this.changeRemindToInstallWoofyAppModalVisibility
  				}
  			/>

  			{this.state.openIGBModal && (
  				<AlertModal
  					isOpen={this.state.openIGBModal}
  					toggle={this.toggleOpenIGBModal}
  					message="You can only post to one Instagram post at a time"
  				/>
  			)}
  			<CaptionGenerationModal
  				open={openCaptionGenerationModal}
  				handleClose={this.handleCloseCaptionGenerationModal}
  				handleAppendCaption={this.handleAppendCaption}
  			/>
  		</Fragment>
  	);
  }
}

const mapStateToProps = (state) => ({
	accountsList: selectors.getAccounts(state),
	settings: selectors.getSettings(state),
	user: selectors.getCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
	getAccountsFromDBAction: bindActionCreators(getAccountsFromDB, dispatch),
	getSettingsAction: bindActionCreators(getSettings, dispatch),
	getNotificationsAction: bindActionCreators(getNotifications, dispatch),
	getCurrentUserAction: bindActionCreators(getCurrentUser, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalendarPostModal));
